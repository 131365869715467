/*==============================================================================
// File:        _title.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared title component - SASS
//============================================================================*/

.zen-title {
  padding: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    color: var(--rk-default);
  }

  @include modifier("light") {
    color: var(--rk-base-white);
  }

  @include modifier("dark") {
    color: var(--rk-base-black);
  }

  @include modifier("primary") {
    color: var(--rk-primary);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("hr-primary") {
    margin: var(--rk-m-full-xl);

    &:after {
      @include pseudo;

      border-bottom: 1px solid var(--rk-primary);
      margin: var(--rk-m-df) auto;
      width: 100px;
      right: 0;
      left: 0;
    }
  }

  @include modifier("category") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 21px);

    border-bottom: 1px solid var(--rk-secondary);
    padding: 0 0 var(--rk-p-sm);
    margin: var(--rk-m-full-md);
    color: var(--rk-secondary);
    font-weight: bold;
  }

  @include modifier("booking") {
    margin: var(--rk-m-sm) 0 var(--rk-m-xs);
    letter-spacing: normal;
    text-transform: none;
    font-style: italic;
    color: var(--rk-text);
  }

  @include modifier("extra-large") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 48px, 80px);
    @include fluid-prop(line-height, $viewport-xs, $viewport-xl, 48px, 80px);
  }

  @include modifier("large") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 48px, 60px);
    @include fluid-prop(line-height, $viewport-xs, $viewport-xl, 48px, 60px);
  }
}

/*------------------------------------------------------------------------------
// Extend title element & modifiers
//----------------------------------------------------------------------------*/

%zen-title {
  @extend .zen-title;
}

%zen-title--light {
  @extend .zen-title--light;
}

%zen-title--dark {
  @extend .zen-title--dark;
}

%zen-title--primary {
  @extend .zen-title--primary;
}

%zen-title--secondary {
  @extend .zen-title--secondary;
}

%zen-title--category {
  @extend .zen-title--category;
}
