/*==============================================================================
// File:        _slick-slider.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Slick Slider styles & overrides - SASS
//============================================================================*/

.slick-slider {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  display: block;
}

.slick-list {
  position: relative;
  overflow: hidden;
  margin: 0 $m-xxl;
  display: block;
  padding: 0;

  &:focus {
    outline: none;
  }

  @include adjoin("dragging") {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  left: 0;
  top: 0;

  &:before,
  &:after {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  position: relative;
  margin: var(--rk-m-md) 0 0;
  justify-content: center;
  align-items: center;
  min-height: 1px;
  display: none;
  // height: 100%;
  float: left;

  [dir="rtl"] & {
    float: right;
  }

  img {
    border: 1px solid $base-white;
    max-width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  }

  @include adjoin("slick-loading") {
    img {
      display: none;
    }
  }

  @include adjoin("dragging") {
    img {
      pointer-events: none;
    }
  }

  .slick-initialized & {
    display: flex;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    border: 1px solid transparent;
    display: block;
    height: auto;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

/*------------------------------------------------------------------------------
// Slick Slider Overrides
//----------------------------------------------------------------------------*/

.slick-arrow {
  position: absolute;
  line-height: 1em;
  background: none;
  font-size: 75px;
  color: $primary;
  border: none;
  opacity: 1;
  padding: 0;
  z-index: 1;
  top: 45%;

  @include media-breakpoint-down(xs) {
    font-size: 55px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    outline: 0;
    opacity: 1;
  }

  @include adjoin("slick-prev") {
    left: 0;
  }

  @include adjoin("slick-next") {
    right: 0;
  }
}
