/*==============================================================================
// File:        _select.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared select component - SASS
//============================================================================*/

.zen-select {
  --rkts-background-colour: transparent;
  --rkts-background-size: 22px;

  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: var(--rkts-background-colour);
  background-size: var(--rkts-background-size);
  border-radius: var(--rk-border-radius-df);
  background-image: var(--rk-select-image);
  border: 1px solid var(--rk-base-grey);
  font-weight: var(--rk-font-normal);
  font-size: var(--rk-font-size-df);
  z-index: var(--rk-z-index-df);
  background-repeat: no-repeat;
  background-clip: padding-box;
  background-position: right;
  padding: .375rem .75rem;
  color: var(--rk-text);
  position: relative;
  line-height: 1.5;
  appearance: none;
  display: block;
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 .25rem $primary-focus;
    background-color: var(--rkts-background-colour);
    border-color: var(--rk-primary-light);
    color: var(--rk-text);
    outline: 0;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("currency-switcher") {
    padding: .575rem 1.75rem .575rem .75rem;
    font-weight: var(--rk-font-semi-bold);
    border-color: rgba(0, 0, 0, 12%);
    color: var(--rk-text);

    option {
      color: var(--rk-text);

      &:first-child {
        color: var(--rk-text);
      }
    }

    &:focus {
      color: var(--rk-text);
    }
  }

  @include modifier("quick-links") {
    border: none;
    font-weight: bold;
  }

  @include modifier("default") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, $font-size-xs, $font-size-md);

    text-transform: uppercase;
    position: relative;
    color: var(--rk-text);
  }

  @include modifier("large") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, $font-size-sm, $font-size-xl);
  }

  @include modifier("small") {
    padding: var(--rk-p-xs) 35px var(--rk-p-xs) 8px;
    font-size: var(--rk-font-size-xs);
    max-height: 25px;
  }

  @include modifier("w-auto") {
    width: auto;
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-select {
  @extend .zen-select;
}

%zen-select--default {
  @extend .zen-select--default;
}

%zen-select--large {
  @extend .zen-select--large;
}

%zen-select--small {
  @extend .zen-select--small;
}

%zen-select--w-auto {
  @extend .zen-select--w-auto;
}
