/*==============================================================================
// File:        _cta.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared cta component - SASS
//============================================================================*/

.zen-cta {
  position: relative;
  padding: var(--rk-p-df);
  color: var(--rk-text);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("text") {
    color: var(--rk-text);
  }

  /*------------------------------------------------------------------------------
  // Mr Zen SPPB Feature Box
  //----------------------------------------------------------------------------*/

  @include element("featurebox-image") {
    @include flex--center;

    margin: 0;

    &:before {
      @include pseudo;

      background: var(--rk-image-overlay-bg);
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }

    &:hover {
      &:before {
        background: var(--rk-image-overlay-bg-hover);
      }
    }
  }

  @include element("date") {
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: var(--rk-font-size-xs);
    padding: var(--rk-p-xs);
    align-self: flex-start;
    font-style: italic;
    font-weight: var(--rk-font-bold);
  }

  @include element("featurebox-icon") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 100px, 140px);

    color: var(--rk-primary);
  }

  @include element("featurebox-content") {
    border: 2px solid var(--rk-border-light);
    font-weight: var(--rk-font-bold);
    position: relative;
    color: var(--rk-base-white);
    align-items: end;
    padding: var(--rk-p-df);
    display: flex;
    height: 100%;

    &:after {
      @include pseudo;

      @extend %fontello;

      content: "\e841";
      font-size: 20px;
      padding: 10px;
      bottom: 10px;
      right: 10px;
      border: 2px solid var(--rk-base-white);
    }

    p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 32px);

      text-shadow: 1px 1px 10px var(--rk-text-shadow-light);
      border-left: 4px solid var(--rk-primary);
      padding: var(--rk-p-xs) 80px 0 var(--rk-p-df);
      margin: 0 !important;
      line-height: normal;
      position: absolute;
      width: 100%;
      padding-right: 80px;
    }
  }

  @include element("featurebox-caption") {
    font-weight: var(--rk-font-semi-bolder);
    font-size: var(--rk-font-size-xs);
    background: var(--rk-primary);
    padding: .25em .55em;
    position: absolute;
    text-align: center;
    font-style: italic;
    left: 15px;
  }

  /*------------------------------------------------------------------------------
  // Mr Zen SPPB Feature Button
  //----------------------------------------------------------------------------*/

  @include element("featurebutton-image") {
    margin: 0 var(--rk-m-df) 0 0;
  }

  @include element("featurebutton-icon") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 30px, 70px);

    margin: 0 var(--rk-m-df) 0 0;
    line-height: 1em;
    color: var(--rk-primary);
  }

  @include element("featurebutton-title") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 24px);

    font-family: var(--rk-primary-font);
    text-transform: uppercase;
    color: var(--rk-text);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("related-article") {
    min-height: 400px;

    @include media-breakpoint-down(lg) {
      min-height: 300px;
    }

    @include media-breakpoint-down(md) {
      min-height: 250px;
    }
  }

  @include modifier("pricing") {
    background-color: rgba($green, .2);
  }

  @include modifier("person") {
    .zen-cta__inner {
      background: var(--rk-grey-lightest);
      padding: var(--rk-p-sm);
    }

    .zen-cta__image {
      height: 300px;
      background-size: cover;
      background-position: center;
      background-color: var(--rk-base-white);
    }

    .zen-cta__content {
      height: 112px;

      .zen-icon {
        margin-right: 0;
      }
    }
  }

  @include modifier("blog-featured") {
    background: var(--rk-base-grey-light);
    height: 100%;
    background-size: cover;
  }

  @include modifier("no-pad") {
    padding: 0;
  }

  @include modifier("btn") {
    display: inline-block;
    text-align: center;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @include modifier("featurebox") {
    margin: 0 auto var(--rk-m-xl);
    overflow: hidden;
    padding: 0;

    .sppb-addon-text-block {
      position: absolute;
      padding: var(--rk-p-df);
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }
  }

  @include modifier("featurebutton") {
    @include flex--center;
    @include insert-shadow(0, 0, 10px, -2px, var(--rk-shadow));

    border-top-right-radius: var(--rk-border-radius-df);
    border-top-left-radius: var(--rk-border-radius-df);
    border: 1px solid var(--rk-base-grey-light);
    border-bottom: 6px solid var(--rk-primary);
    text-align: center;
    margin: 0 0 var(--rk-m-xl);
    padding: var(--rk-p-sm);
  }

  /*------------------------------------------------------------------------------
  // Box Type
  //----------------------------------------------------------------------------*/

  @include modifier("info") {
    .zen-cta__featurebox-content p {
      padding: 0 60px var(--rk-p-xxl) 0;
      border: none;
    }
  }

  /*------------------------------------------------------------------------------
  // Box Size
  //----------------------------------------------------------------------------*/

  @include modifier("small") {
    container-type: inline-size;
    container-name: small-cta;
    max-width: 300px;
  }

  @include modifier("medium") {
    container-type: inline-size;
    container-name: medium-cta;
    max-width: 500px;
  }

  @include modifier("large") {
    container-type: inline-size;
    container-name: large-cta;
    max-width: none;
  }

  /*------------------------------------------------------------------------------
  // Box Shape
  //----------------------------------------------------------------------------*/

  @include modifier("square") {
    aspect-ratio: 1/1;

    .zen-cta__featurebox-image {
      height: 100%;
    }

    .sppb-img-responsive,
    .img-fluid {
      max-width: fit-content;
      height: 100%;
    }
  }

  @include modifier("rectangle") {
    aspect-ratio: 16/7.7;

    .zen-cta__featurebox-image {
      height: 100%;
    }

    .sppb-img-responsive,
    .img-fluid {
      aspect-ratio: 16/9;
    }
  }

  /*------------------------------------------------------------------------------
  // Font Size Changes
  //----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend cta element & modifiers
//----------------------------------------------------------------------------*/

%zen-cta {
  @extend .zen-cta;
}

/*------------------------------------------------------------------------------
// Container queries
//----------------------------------------------------------------------------*/

// Small CTA.
//
// media query
@media (max-width: 991px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 24px);
  }
}

@media (max-width: 768px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 20px);

    &:after {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 16px, 18px);
  }
}
