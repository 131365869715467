/*==============================================================================
// File:        _button.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared button component - SASS
//============================================================================*/

.zen-btn {
  @include insert-transition(all, .5s, ease, .1s);

  letter-spacing: var(--rk-letter-space-md);
  text-transform: uppercase;
  z-index: var(--rk-z-index-bottom);
  font-size: var(--rk-font-size-xs);
  font-weight: var(--rk-font-semi-bolder);
  border-color: var(--rk-primary);
  justify-content: center;
  text-decoration: none;
  display: inline-flex;
  align-items: end;
  background: var(--rk-primary);
  border: 3px solid var(--rk-primary);
  padding: var(--rk-p-df) var(--rk-p-md);
  color: var(--rk-base-white);
  position: relative;
  line-height: 1em;
  border-radius: 0;

  i {
    margin-right: var(--rk-p-sm);
  }

  &:active,
  &:focus {
    background: var(--rk-primary-mix-dark);
    color: var(--rk-base-white);
    border: 3px solid var(--rk-primary-mix-dark);
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:hover {
    background: var(--rk-secondary);
    color: var(--rk-base-white);
    border: 3px solid var(--rk-secondary);
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;

    i {
      color: var(--rk-primary);
    }
  }

  &:disabled {
    border-color: var(--rk-base-grey);
    background: var(--rk-base-grey);
    cursor: not-allowed;
    color: var(--rk-text);
    opacity: .6;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("outlined-pink") {
    border: 3px solid var(--rk-primary);
    background: var(--rk-base-white);
    font-weight: var(--rk-font-bold);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-secondary);
      color: var(--rk-secondary);
      background: transparent;
    }
  }

  @include modifier("alternate") {
    border: 3px solid var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-primary);
      background: var(--rk-primary);
      color: var(--rk-secondary);
    }
  }

  @include modifier("alternate-white") {
    border: 3px solid var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-primary-mix-dark);
      background: var(--rk-primary-mix-dark);
      color: var(--rk-base-white);
    }
  }

  @include modifier("white") {
    padding: var(--rk-p-xs);
    border: 3px solid var(--rk-base-white);
    background: var(--rk-base-white);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-base-white);
      background: var(--rk-base-white);
      color: var(--rk-primary);
    }
  }

  @include modifier("transparent") {
    padding: var(--rk-p-df) var(--rk-p-md);
    border: 2px solid var(--rk-primary);
    background: transparent;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border: 2px solid var(--rk-primary);
      background: transparent;
      color: var(--rk-primary);
    }
  }

  @include modifier("invert") {
    background: var(--rk-primary-light);
    border: 3px solid var(--rk-primary-light);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-primary);
      background: var(--rk-primary);
    }
  }

  @include modifier("navigation-extra") {
    color: var(--rk-base-black);
    text-transform: none;
    border: none;
    background: none;
    font-size: var(--rk-font-size-md);
    padding: var(--rk-p-df);
    border-top: 1px solid var(--rk-base-grey);

    &:hover {
      text-decoration: underline;
      color: var(--rk-base-black);
      border: none;
      border-top: 1px solid var(--rk-base-grey);
      background: none;
    }
  }

  @include modifier("disabled") {
    border: 3px solid var(--rk-base-grey);
    background: var(--rk-base-grey);
    color: var(--rk-text);
    opacity: .8;

    &:hover,
    &:focus {
      border: 3px solid var(--rk-base-grey);
      background: var(--rk-base-grey);
      color: var(--rk-text);
      opacity: .8;
    }
  }

  @include modifier("secondary") {
    border: 3px solid var(--rk-secondary);
    background: var(--rk-secondary);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-secondary-light);
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary);
      color: var(--rk-base-white);
    }
  }

  @include modifier("opaque") {
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      background: transparent;
      color: var(--rk-base-white);
    }
  }

  @include modifier("link") {
    border: none;
    border-bottom: 2px solid var(--rk-primary) !important;
    color: var(--rk-primary) !important;
    font-weight: var(--rk-font-bold);
    background: transparent;
    margin: var(--rk-m-sm) 0 0;
    padding: var(--rk-p-sm) 0;

    &:hover,
    &:focus {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid var(--rk-secondary);
      color: var(--rk-secondary) !important;
      background: transparent;
    }
  }

  @include modifier("dropdown") {
    padding: var(--rk-p-df) var(--rk-p-md) var(--rk-p-df) 0;
    font-weight: var(--rk-font-bold);
    text-transform: capitalize;
    background: transparent;
    color: var(--rk-text);
    border: none;

    &:after {
      top: 12px;
    }

    &:hover,
    &:focus {
      color: var(--rk-primary-light);
      background: transparent;
      border: none;
    }

    @include adjoin("all-filters") {
      padding: var(--rk-p-df) 25px var(--rk-p-df) 0;

      &:after {
        @include pseudo;

        @extend %fontello;

        font-size: var(--rk-font-size-lg);
        color: var(--rk-primary);
        content: "\e81e";
        border: none;
        right: 2px;
        top: 10px;
      }
    }
  }

  @include modifier("default") {
    border: 3px solid var(--rk-default);
    background: var(--rk-default);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-default-light);
      background: var(--rk-default-light);
    }
  }

  @include modifier("dark") {
    background: var(--rk-dark);
    border: 3px solid var(--rk-dark);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-dark);
      background: var(--rk-dark);
      color: var(--rk-secondary);
    }
  }

  @include modifier("warning") {
    border: 3px solid var(--rk-secondary-light);
    background: var(--rk-secondary-light);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-secondary-light);
      background: var(--rk-secondary-light);
    }
  }

  @include modifier("base-grey-light") {
    border: 3px solid var(--rk-base-grey-light);
    background: var(--rk-base-grey-light);
    color: var(--rk-base-black);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-base-grey);
      background: var(--rk-base-grey);
      color: var(--rk-base-black);
    }
  }

  @include modifier("danger") {
    background: var(--rk-primary-light);
    border: 3px solid var(--rk-primary-light);

    &:hover,
    &:focus {
      border: 3px solid var(--rk-primary-light);
      background: var(--rk-primary-light);
    }
  }

  @include modifier("icon") {
    padding: 9px 12px;

    i {
      margin: 0;
    }
  }

  @include modifier("icon-submit") {
    font-size: var(--rk-font-size-lg);
    padding: var(--rk-p-sm) var(--rk-p-md);
    background: var(--rk-primary);
    position: absolute;
    color: var(--rk-base-white);
    right: 1px;
    top: 1px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-off-white);
      outline: none;
    }
  }

  @include modifier("full-size") {
    width: 100%;
  }

  @include modifier("placeholder") {
    min-width: 100px;
  }

  @include modifier ("grade") {
    max-width: 300px;
  }

  @include modifier("extra-pad-lg") {
    padding: var(--rk-p-md) var(--rk-p-lg);
  }

  @include modifier("extra-pad-md") {
    padding: var(--rk-p-sm) var(--rk-p-md);
  }

  @include modifier("extra-pad-sm") {
    padding: var(--rk-p-sm) var(--rk-p-sm);
  }

  @include modifier("extra-y-pad-lg") {
    padding-bottom: var(--rk-p-lg) !important;
    padding-top: var(--rk-p-lg) !important;
  }

  @include modifier("scroll") {
    border: 1px solid var(--rk-primary-mix-dark);
    border-radius: var(--rk-border-radius-df);
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: 30px;
    display: none;
    padding: 8px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
    }

    @include adjoin("show-btn") {
      display: flex;
    }

    :before {
      vertical-align: top;
    }
  }

  /*------------------------------------------------------------------------------
	// Outlined Buttons
	//----------------------------------------------------------------------------*/

  @include modifier("outlined-primary") {
    border: 3px solid var(--rk-primary);
    background: var(--rk-base-white);
    font-weight: var(--rk-font-bold);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-secondary);
      color: var(--rk-secondary);
      background: transparent;
    }
  }

  @include modifier("outlined-white") {
    box-shadow: 0 0 12px -2px var(--rk-box-shadow);
    border: 3px solid var(--rk-base-white);
    text-shadow: 0 1px 5px var(--rk-base-black);
    font-weight: var(--rk-font-bold);
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      box-shadow: 0 0 12px -2px var(--rk-box-shadow);
      border-color: var(--rk-base-white);
      background: var(--rk-base-white);
      color: var(--rk-primary);
      text-shadow: none;
    }
  }

  @include modifier("outlined-secondary") {
    border: 3px solid var(--rk-secondary);
    background: var(--rk-base-white);
    font-weight: var(--rk-font-bold);
    color: var(--rk-text);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary);
      color: var(--rk-base-white);
    }
  }

  @include modifier("outlined-opaque") {
    border: 3px solid var(--rk-primary);
    font-weight: var(--rk-font-bold);
    background: transparent;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-secondary);
      color: var(--rk-secondary);
      background: transparent;
    }
  }

  @include modifier("quick-link") {
    transition: all .3s ease-in-out;
    background: var(--rk-base-white);
    padding: var(--rk-p-md) var(--rk-p-df);
    font-size: var(--rk-font-size-df);
    margin: 0 0 var(--rk-m-df);
    text-align: left;
    border-radius: 0;
    color: var(--rk-base-black);
    border: 0;
    text-transform: none;
    letter-spacing: 0;
    position: relative;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-primary);
      border: none;
    }

    &.active:after {
      right: 0;
    }

    &.active:before {
      opacity: 1;
      height: 10px;
    }

    &:before {
      opacity: 0;
      transition: all .3s ease-in-out .15s;
      content: "";
      background: var(--rk-primary);
      height: 0;
      width: 15px;
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      clip-path: polygon(50% 0, 0 100%, 100% 100%);
    }

    &:after {
      content: "";
      background: var(--rk-primary);
      position: absolute;
      left: 0;
      right: 100%;
      transition: all .3s ease-in-out;
      bottom: -16px;
      height: 5px;
    }
  }

  /*------------------------------------------------------------------------------
	// Accordion Buttons
	//----------------------------------------------------------------------------*/

  @include modifier("accordion-main") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 16px, 18px);

    border: none;

    /*Reset Border*/
    border-top: 1px solid var(--rk-border-colour);
    background: var(--rk-base-grey-lightest);
    font-weight: var(--rk-font-bold);
    justify-content: start;
    border-radius: 0;
    padding: var(--rk-p-md);
    color: var(--rk-text);
    margin: 0;
    position: relative;

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-secondary);
      cursor: pointer;
      outline: none;
    }

    &:after {
      @extend %fontello;

      transform: rotate(90deg);
      content: "\f105";
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 30px;
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;
    }

    &:not(.collapsed):after {
      transform: rotate(-90deg);
    }
  }

  @include modifier("accordion-sub") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 16px);

    transition: all .3s ease-in-out;
    background: var(--rk-base-white);
    padding: var(--rk-p-md) var(--rk-p-df);
    font-weight: var(--rk-font-semi-bolder);
    color: var(--rk-base-black);
    text-transform: capitalize;
    justify-content: start;
    border-radius: 0;
    margin: 0;
    border: 0;
    position: relative;

    &:not(.collapse),
    &:hover {
      background: var(--rk-base-white) !important;
      color: var(--rk-base-black);
      cursor: pointer;
      outline: none;
      border: none;
    }

    &:focus {
      background: var(--rk-base-grey-light);
      color: var(--rk-text);
      border: none;
    }

    &:after {
      @extend %fontello;

      font-size: var(--rk-icon-size-sm);
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;
      transform: rotate(0deg);
      content: "\f107";
      position: absolute;
      background: none;
      right: 20px;
      top: 20px;

      @include media-breakpoint-down(md) {
        top: 15px;
      }
    }

    &:not(.collapsed):after {
      transform: rotate(180deg);
      background: none;
    }

    .zen-accordion {
      .zen-accordion {
        @include modifier("accordion-sub") {
          background: var(--rk-base-grey-light) !important;
          color: var(--rk-text);
        }
      }
    }
  }

  @include modifier("accordion-itinerary") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 16px);

    font-weight: var(--rk-font-semi-bolder);
    transition: all .3s ease-in-out;
    cursor: pointer;
    padding: var(--rk-p-md) var(--rk-p-df);
    margin: 0 0 var(--rk-m-df);
    text-align: left;
    border-radius: 0;
    color: var(--rk-base-black);
    border-bottom: 1px solid var(--rk-base-grey-light);

    @include media-breakpoint-down(lg) {
      font-size: var(--rk-font-size-lead);
      border: none;
    }

    &[aria-expanded="true"] {
      border: none;
    }

    &:not(.collapse),
    &:hover {
      color: var(--rk-base-black);
      outline: none;
    }

    &:after {
      transform: rotate(90deg);
    }

    &:not(.collapsed):after {
      transform: rotate(-90deg);
    }
  }

  @include modifier("accordion-mobilemenu") {
    padding: 13.6px var(--rk-p-xl) 13.5px var(--rk-p-sm);
    font-weight: var(--rk-font-bold);
    position: relative;

    &:after {
      @extend %fontello;

      transform: rotate(0deg);
      content: "\f105";
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      color: var(--rk-grey);
    }
  }

  /*------------------------------------------------------------------------------
	// Button style for flyout Menu
	//----------------------------------------------------------------------------*/

  @include modifier("mobile-nav") {
    border-top: 1px solid var(--rk-base-grey-light);
    font-size: var(--rk-font-size-md);
    font-weight: var(--rk-font-bold);
    padding: 16px;
  }

  @include modifier("flyout-mobilemenu") {
    font-size: var(--rk-font-size-md);
    border: none;
    position: relative;
    padding: var(--rk-p-md);
    color: var(--rk-primary);
    font-weight: var(--rk-font-bold);
    background: var(--rk-base-white);
    letter-spacing: 0;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-primary);
      outline: none;
      border: none;
    }

    &:after {
      @extend %fontello;

      content: "\e807";
      color: var(--rk-secondary);
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      transform: rotate(0deg);
    }
  }

  /*------------------------------------------------------------------------------
	// Filter Panel
	//----------------------------------------------------------------------------*/

  @include modifier("navigate") {
    border: none;

    /*Reset Border*/
    border-bottom: 1px solid var(--rk-base-grey);
    justify-content: space-between;
    text-transform: uppercase;
    background: var(--rk-base-white);
    align-items: center;
    font-weight: normal;
    margin: 0 0 var(--rk-m-df);
    text-align: unset;
    color: var(--rk-secondary);
    display: flex;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  /*Default btn text size medium*/
  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-btn {
  @extend .zen-btn;
}

%zen-btn--alternate {
  @extend .zen-btn--alternate;
}

%zen-btn--secondary {
  @extend .zen-btn--secondary;
}

%zen-btn--opaque {
  @extend .zen-btn--opaque;
}

%zen-btn--accordion-itinerary {
  @extend .zen-btn--accordion-itinerary;
}

%zen-btn--link {
  @extend .zen-btn--link;
}

%zen-btn--default {
  @extend .zen-btn--default;
}

%zen-btn--warning {
  @extend .zen-btn--warning;
}

%zen-btn--danger {
  @extend .zen-btn--danger;
}

%zen-btn--dark {
  @extend .zen-btn--dark;
}

%zen-btn--outlined-primary {
  @extend .zen-btn--outlined-primary;
}

%zen-btn--outlined-secondary {
  @extend .zen-btn--outlined-secondary;
}

%zen-btn--outlined-opaque {
  @extend .zen-btn--outlined-opaque;
}

%zen-btn--full-size {
  @extend .zen-btn--full-size;
}

%zen-btn--text-xs {
  @extend .zen-btn--text-xs;
}

%zen-btn--accordion-main {
  @extend .zen-btn--accordion-main;
}

%zen-btn--accordion-sub {
  @extend .zen-btn--accordion-sub;
}
