/*==============================================================================
// File:        _input.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared input component - SASS
//============================================================================*/

.zen-input {
  border: 1px solid var(--rk-base-grey-darker);
  border-radius: var(--rk-border-radius-df);
  font-size: var(--rk-font-size-df);
  background: var(--rk-base-white);
  line-height: 1.5em;
  padding: 12px;
  height: auto;

  &:focus {
    border-color: var(--rk-primary);
    box-shadow: none;
    outline: none;
    color: var(--rk-text);
  }

  &::-webkit-input-placeholder {
    color: var(--rk-base-grey-dark);
    overflow: visible;
  }

  &:-ms-input-placeholder {
    color: var(--rk-base-grey-dark);
    overflow: visible;
  }

  &::placeholder {
    color: var(--rk-base-grey-dark);
    overflow: visible;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("top-search") {
    border-color: var(--rk-grey-lightest);
    background: var(--rk-grey-lightest);

    &::-webkit-input-placeholder {
      color: var(--rk-base-black);
    }

    &:-ms-input-placeholder {
      color: var(--rk-base-black);
    }

    &::placeholder {
      color: var(--rk-base-black);
    }

    &:focus {
      border-color: var(--rk-grey-lightest);
      background: var(--rk-grey-lightest);
    }
  }
}

/*------------------------------------------------------------------------------
// Extend input element & modifiers
//----------------------------------------------------------------------------*/

%zen-input {
  @extend .zen-input;
}
