/*==============================================================================
// File:        _blog-article.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main blog article styles for the website - SASS
//============================================================================*/

.zen-blog-article {
  padding: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("info-bar") {
    border-top: 1px solid var(--rk-base-grey-lighter);
  }

  @include element("social-bar") {
    justify-content: space-between;
    margin: 0 0 var(--rk-p-df);
    align-items: center;
    display: flex;
  }

  @include element("image") {
    position: relative;
    overflow: hidden;

    &:after {
      border: 2px solid var(--rk-border-light);
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      bottom: 20px;
    }
  }

  @include element("body") {
    margin: 0 auto var(--rk-m-xxl);
    padding: var(--rk-p-md) 0;
    max-width: 1100px;

    img {
      width: 100%;
      height: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      a {
        text-decoration: none !important;
        border-bottom: none !important;
      }
    }

    a:not(.sppb-btn, .zen-link, .zen-btn) {
      @extend %zen-link;
      @extend %zen-link--in-content;
    }
  }
}
