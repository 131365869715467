/*==============================================================================
// File:        _menu.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared menu component - SASS
//============================================================================*/

.top-item a {
  @extend %zen-link--menu-mobile;
}

/*------------------------------------------------------------------------------
// Offcanvas Styling Features
//----------------------------------------------------------------------------*/

.offcanvas {
  box-shadow: 0 0 100px rgba(0, 0, 0, 10%);

  &.show {
    visibility: visible;
  }

  &.mobile-menu {
    border-left: 3px solid var(--rk-primary);
  }

  .menu-close {
    position: absolute;
    z-index: $z-index-upper;
    right: 15px;
    top: 33px;
  }

  .moduletable {
    width: 100%;
  }

  .zen-list {
    li {
      a {
        font-weight: normal;
        text-decoration: none;
      }

      a:not(.accordion-link).active {
        color: var(--rk-secondary);
        font-weight: bold;
      }
    }
  }
}

/*------------------------------------------------------------------------------
// Zen Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu {
  .dropdown-toggle {
    color: var(--rk-base-black);
    padding: var(--rk-p-sm) var(--rk-p-md) var(--rk-p-sm) var(--rk-p-sm);
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      color: var(--rk-primary);
    }

    @include adjoin("dropdown-item") {
      padding: var(--rk-p-sm) var(--rk-p-md) var(--rk-p-sm) var(--rk-p-sm);
      border-bottom: 1px solid var(--rk-border-colour);
      border-right: 15px solid var(--rk-base-white);
      border-left: 15px solid var(--rk-base-white);
      position: relative;

      &:after {
        content: "\f105";
      }
    }
  }

  .dropdown-menu {
    border: 1px solid var(--rk-border-colour);
    box-shadow: 0 0 20 rgba(0, 0, 0, 10%);
    border-top: 3px solid var(--rk-primary);
    border-radius: 0;
    top: 100%;
    right: 0;
  }

  .sub-item {
    display: inline-block;
    padding: var(--rk-p-xs) var(--rk-p-sm);
    width: 100%;
  }

  /*------------------------------------------------------------------------------
  // Elements
  //----------------------------------------------------------------------------*/

  @include element("top") {
    border-bottom: 1px solid var(--rk-base-grey-lighter);
    justify-content: end;
    align-items: center;
    margin: 0 -30px;
    display: flex;
    height: 40px;

    .nav-header,
    .nav-link {
      @extend %zen-link--default;

      font-size: var(--rk-font-size-xs);
      padding-left: var(--rk-p-df);
    }
  }

  @include element("main") {
    .nav-item {
      padding: 0 1vw;
    }

    .mobile {
      box-shadow: var(--rk-shadow-base);
    }

    .nav-header,
    .nav-link {
      @extend %zen-link--menu-main;
    }

    .sub-item {
      display: inline-block;
      padding: var(--rk-p-sm) var(--rk-p-sm);
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      @include insert-transition(all, .3s, ease-in-out, 0s);

      background: var(--rk-base-white);
      z-index: var(--rk-z-index-top);
      font-weight: normal;
      min-width: 300px;
      position: fixed;
      overflow: auto;
      max-width: 65%;
      right: -480px;
      height: 100%;
      opacity: 0;
      margin: 0;
      top: 0;
    }
  }

  @include element("footer") {
    .nav-header,
    .nav-link {
      background: inherit;
    }
  }

  .zen-menu-mobile--bold li .zen-link {
    font-weight: 700 !important;
  }

  /*------------------------------------------------------------------------------
  // Mega Menu Styling (needs refactoring)
  //----------------------------------------------------------------------------*/

  .zen-mega-menu {
    .dropdown-toggle {
      font-weight: var(--rk-font-bold);
      position: relative;
      font-size: 16px;

      &:after {
        font-size: var(--rk-font-size-md);
        font-weight: var(--rk-font-bold);
        top: 12px;
      }
    }

    .note {
      font-size: 16px;
    }

    .dropdown-menu {
      // transition is used for a demo
      transition: .4s all;
      box-shadow: 0 5px 15px rgba(var(--rk-base-black), .1);
      border-right: 1px solid var(--rk-border-light);
      border-left: 1px solid var(--rk-border-light);
      border-bottom: 3px solid var(--rk-primary);
      position: absolute;
      border-radius: 0;
      min-width: 85vw;
      height: 620px;
      top: 99%;
      right: 0;
      left: 0;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      width: 100vw;
      margin: 0 0 0 -30px;
      border-top: 1px solid var(--rk-base-grey-lighter);

      &::-webkit-scrollbar {
        display: none;
      }

      .subitems {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 2fr;
        padding: var(--rk-p-md) var(--rk-p-lg);
      }
    }

    .nav-tabs {
      padding: var(--rk-p-xl) var(--rk-p-xxl);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--rk-primary);
      font-weight: var(--rk-font-semi-bold);
      font-size: var(--rk-font-size-xs);
      border-bottom: none;

      @include media-breakpoint-down(lg) {
        padding: var(--rk-p-xl) 0;
      }

      .zen-link {
        border-left: 1px solid var(--rk-grey-lightest);
        border-right: 1px solid var(--rk-grey-lightest);
        color: var(--rk-primary);
        padding: var(--rk-p-sm) 3vw;
        font-weight: var(--rk-font-semi-bold);
        font-size: var(--rk-font-size-xs);
        text-transform: uppercase;
        transition: all .2s ease-in-out;

        &:hover {
          background: var(--rk-base-grey-lighter);
        }
      }

      li.deeper {
        margin: 0;
      }

      .sub-item-deeper {
        letter-spacing: var(--rk-letter-space-md);
        font-weight: var(--rk-font-bold);
        position: relative;
      }

      .sub-item-deeper:hover {
        background: none;

        &:before {
          content: "";
          position: absolute;
          background: #F4F4F4;
          inset: 0 30px;
          z-index: -1;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          width: 10px;
          height: 5px;
          background: #F4F4F4;
          left: 50%;
          margin-left: -5px;
          clip-path: polygon(100% 0, 1% 0, 50% 100%);
        }
      }

      .sub-item-deeper.active {
        position: relative;
        color: var(--rk-base-white);

        &:hover {
          background: none;
        }

        &:before {
          content: "";
          position: absolute;
          background: var(--rk-primary);
          inset: 0 30px;
          z-index: -1;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          width: 10px;
          height: 5px;
          background: var(--rk-primary);
          left: 50%;
          margin-left: -5px;
          clip-path: polygon(100% 0, 1% 0, 50% 100%);
        }
      }
    }

    .tab-content {
      position: absolute;
      left: 30px;
      right: 30px;
      top: 100px;
      display: none;

      &.active {
        display: block !important;
      }

      .sub-item {
        border: none;
        background: transparent;
        font-size: var(--rk-font-size-df);
        font-weight: var(--rk-font-bold);
        text-transform: none;
        color: var(--rk-secondary);
        padding: var(--rk-p-sm);
        transition: all .2s ease-in-out;

        &:hover {
          background: var(--rk-base-grey-lighter);
        }

        &.active {
          text-decoration: underline;
          text-decoration-color: var(--rk-primary);
          text-decoration-thickness: 2px;
          text-underline-offset: 5px;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .offcanvas-backdrop {
      display: none !important;
    }
  }
}

.zen-body:before {
  content: "";
  position: fixed;
  top: 100px;
  width: 130%;
  z-index: 17;
  margin-left: -35px;
}

.zen-body.js-menu-open:before {
  bottom: 0;
  backdrop-filter: blur(5px);
  transition: all .3s ease-in-out;
  background: rgba(0, 0, 0, 30%);
}

/*------------------------------------------------------------------------------
// Mobile menu
//----------------------------------------------------------------------------*/

.zen-menu-open {
  @include media-breakpoint-down(lg) {
    max-height: 100vh;
    overflow: hidden;

    .zen-overlay {
      display: block;
      opacity: 1;
    }

    .sppb-section,
    .zen-header,
    .zen-body {
      z-index: auto !important;
    }

    .sp-page-builder {
      z-index: -1;
    }

    .zen-menu__main {
      transform: translateX(-480px);
      opacity: 1;
    }
  }
}

.mobile-menu {
  @include media-breakpoint-down(lg) {
    box-shadow: none;

    .zen-link {
      text-transform: capitalize;
      font-size: var(--rk-font-size-lead);
      line-height: var(--rk-line-height-xs);
    }

    .top-item {
      .zen-link {
        padding: var(--rk-p-md) var(--rk-p-df) !important;
      }
    }

    .zen-flyout-back {
      padding-bottom: var(--rk-p-sm);
    }

    .zen-menu-flyout {
      .offcanvas-body {
        // Accounts for top, offcanvas-header height and padding for the overflow-scroll to not cut elements off
        height: calc(100vh - 92px - 49.5px - 100px);
      }

      .zen-btn-third {
        padding: var(--rk-p-md) var(--rk-p-df) !important;
        line-height: var(--rk-line-height-xs);
      }

      .zen-link {
        &.child-sub-item {
          padding: var(--rk-p-md) var(--rk-p-df) !important;
        }
      }
    }
  }

  @media (max-width: 991px) {
    box-shadow: none;
    border-top: 1px solid var(--rk-base-grey-light);

    .flyout-submenu-offcanvas {
      box-shadow: none;
      border-top: 1px solid var(--rk-base-grey-light);
      padding-top: var(--rk-p-md);

      .offcanvas-body {
        li .zen-link {
          font-weight: 400 !important;
        }
      }
    }
  }
}

.sticky-marker {
  position: absolute;
  right: 10px;
  height: 1px;
  width: 1px;
  bottom: 0;
}

.scroll-transition {
  margin-top: 60px;
}

.fixed-menu {
  @include insert-transition(all, .3s, ease, 0s);

  z-index: var(--rk-z-index-middle);
  background: var(--rk-default);
  position: fixed;
  width: 100%;
  top: 0;
}

/*------------------------------------------------------------------------------
// Image Menu Styling
//----------------------------------------------------------------------------*/

.zen-image-menu {
  .zen-image-menu--dropdown {
    width: 100vw;
    box-shadow: 0 5px 15px rgba(var(--rk-base-black), .1);
    border-right: 1px solid var(--rk-border-light);
    border-left: 1px solid var(--rk-border-light);
    border-top: 1px solid var(--rk-border-light);
    border-bottom: 3px solid var(--rk-primary);
    background: var(--rk-base-white);
    padding: var(--rk-p-sm) var(--rk-p-lg);
    max-height: 80vh;
    overflow: auto;
    position: absolute;
    margin: 0 0 0 -30px;
    top: 99%;
    right: 0;
    left: 0;

    .dropdown-item {
      display: flex;
      flex-direction: column;
    }

    .nav-item {
      padding: 0;

      .icon-signpost:before {
        margin-left: 0;
      }

      a {
        padding: var(--rk-p-sm);
        max-width: 300px;

        span {
          @include fluid-prop(font-size, $viewport-md, $viewport-xl, 16px, 20px);

          font-weight: var(--rk-font-bold);
          padding-top: var(--rk-p-xs);
          color: var(--rk-base-black);
          text-align: left;
        }
      }
    }

    .img-wrapper {
      position: relative;
    }

    .img-wrapper:after {
      content: "";
      position: absolute;
      left: 12px;
      right: 12px;
      top: 12px;
      z-index: $z-index-upper;
      bottom: 15px;
      border: 2px solid rgba(255, 255, 255, 50%);
    }
  }

  .zen-menu__image-note {
    text-transform: none;
    min-height: 50px;
  }

  .zen-link--menu-sub {
    position: relative;
  }

  .dropdown {
    position: inherit;
  }
}

/*------------------------------------------------------------------------------
// Flyout Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu-flyout {
  .offcanvas-body {
    height: 100vh;
    overflow: scroll;
  }

  .zen-btn--mobile-nav {
    color: black;
    padding: var(--rk-p-df) !important;

    &:hover {
      color: var(--rk-base-black);
      text-decoration: underline !important;
    }
  }

  .zen-btn--flyout-mobilemenu {
    color: var(--rk-base-black);
    padding: var(--rk-p-md) var(--rk-p-df) !important;
    background: var(--rk-base-white);
    border-top: 1px solid var(--rk-base-grey-light);
    text-transform: capitalize;
    font-size: var(--rk-font-size-lead);

    &:after {
      content: url("/templates/zenbasej4template/images/menu-arrow.svg");
    }

    &:hover {
      border-top: 1px solid var(--rk-base-grey-light);
      text-decoration: underline !important;
    }
  }

  .zen-btn-third {
    padding: var(--rk-p-sm) !important;
    text-transform: none;
    font-size: var(--rk-font-size-df);
    border-top: 1px solid var(--rk-base-grey-light);

    &:hover {
      text-decoration: underline;
    }
  }

  .zen-link--default-underline {
    border-bottom: none;
  }

  .zen-flyout-back {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }

    .icon-chevron-left {
      &:before {
        content: url("/templates/zenbasej4template/images/back-arrow.svg");
        margin-right: 10px !important;
      }
    }
  }
}

.flyout-menu-offcanvas,
.flyout-submenu-offcanvas {
  position: fixed;
  top: 0;
  background: var(--rk-base-white);
  z-index: $z-index-upper;
  width: 100%;
  max-width: 400px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 50%);
  border-left: 3px solid var(--rk-primary);

  .deeper {
    position: relative;
  }
}

.zen-menu--footer {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }

  .nav-link {
    color: var(--rk-secondary);
    font-weight: var(--rk-font-bold);
    padding-bottom: var(--rk-p-sm);

    @include media-breakpoint-down(md) {
      padding: var(--rk-p-sm) 0;
    }

    &:hover,
    &:focus {
      color: var(--rk-secondary);
    }
  }
}

.zen-menu .zen-mega-menu .dropdown-menu.collapse.show {
  visibility: visible;
  opacity: 1;
}

.zen-menu .zen-mega-menu .dropdown-menu.collapse {
  opacity: 0;
  visibility: visible;
}

.scrolled .zen-menu .zen-mega-menu .dropdown-menu {
  margin: 0;
}

.scrolled .zen-image-menu .zen-image-menu--dropdown {
  margin: 0;
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-menu {
  @extend .zen-menu;
}

%zen-menu__footer {
  @extend .zen-menu__footer;
}
