/*==============================================================================
// File:        _notice.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared notice component - SASS
//============================================================================*/

.zen-notice {
  font-weight: var(--rk-font-bold);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--rk-p-sm);
  flex-wrap: wrap;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("danger") {
    background: var(--rk-danger);
  }

  @include modifier("info") {
    background: var(--rk-primary);
  }

  @include modifier("success") {
    background: var(--bs-success);
  }

  @include modifier("warning") {
    background: var(--bs-warning);
  }
}
