/*==============================================================================
// File:        _badge.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared badge component - SASS
//============================================================================*/

.zen-badge {
  font-size: var(--rk-font-size-xxs);
  font-weight: var(--rk-font-bold);
  background: var(--rk-primary);
  color: var(--rk-base-white);
  padding: .3em .4em;
  vertical-align: baseline;
  align-items: center;
  white-space: nowrap;
  position: relative;
  text-align: center;
  border-radius: 0;
  display: flex;

  &:hover,
  &:focus {
    color: var(--rk-base-white);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("count") {
    padding: var(--rk-p-sm);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    min-height: 20px;
    min-width: 21px;
    font-size: 9px;
    display: none;
    bottom: 1px;
    right: -10px;
    height: 1em;
    width: 1em;

    @include adjoin("show") {
      display: flex;
    }
  }
}
