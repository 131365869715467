/*==============================================================================
// File:        _header.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main header styles for the website - SASS
//============================================================================*/

.zen-header {
  background: var(--rk-base-white);
  z-index: var(--rk-z-index-top);
  position: sticky;
  width: 100%;
  padding: 0;
  top: -1px;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("logo") {
    margin-left: -5px;
    padding: 0;

    img {
      @include insert-transition(height, .3s, ease, 0s);

      height: 58px;

      @include media-breakpoint-down(lg) {
        height: 42px;
      }
    }

    @include media-breakpoint-down(lg) {
      margin: var(--rk-m-sm) 0;
      height: 42px;
    }
  }

  @include element("main") {
    background: var(--rk-base-white);
    justify-content: space-between;
    padding: 1.5rem var(--rk-p-lg);
    align-items: center;
    margin: 0 -30px;
    display: flex;

    @include media-breakpoint-down(lg) {
      padding: var(--rk-p-xs) var(--rk-p-lg);
    }

    .zen-icon.icon-sys-hamburger {
      color: var(--rk-base-black-light);
    }

    .mobile-menu-close {
      display: none;
    }

    @include adjoin("scrolled") {
      box-shadow: 0 4px 24px 0 rgba($base-black, .1);
      position: fixed;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      height: 80px;

      .zen-header__logo img {
        height: 42px;
      }

      @include media-breakpoint-down(md) {
        padding: var(--rk-p-xs) var(--rk-p-df);
      }
    }

    @media (max-width: 400px) {
      .menu-open {
        .icon-sys-hamburger {
          display: none;
        }

        .mobile-menu-close {
          display: block;
        }
      }
    }
  }
}

.zen-menu-open {
  .zen-header {
    z-index: var(--rk-z-index-top);
  }
}
