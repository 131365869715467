/*==============================================================================
// File:        _add-to-any.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Add to any style overrides - SASS
//============================================================================*/

.a2a_svg {
  margin: 0 0 0 var(--rk-m-sm);
  background: none !important;
  color: var(--rk-primary);
  position: relative;

  svg {
    display: none !important;
  }

  &:after {
    @extend %fontello;

    font-size: var(--rk-icon-size-xs);
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
  }
}

.a2a_button_facebook {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\e825";
    }
  }
}

.a2a_button_copy_link {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\f0c5";
    }
  }
}

.a2a_button_facebook_messenger {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\e825";
    }
  }
}

.a2a_button_whatsapp {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\f232";
    }
  }
}

.a2a_button_email {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\f0e0";
    }
  }
}

.a2a_button_sms {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\e860";
    }
  }
}

.a2a_dd {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\e861";
    }
  }
}

.a2a_button_twitter {
  padding: 0;

  .a2a_svg {
    &:after {
      border-radius: var(--rk-border-radius-xl);
      font-size: var(--rk-font-size-xs);
      background: var(--rk-primary);
      color: var(--rk-base-white);
      padding: var(--rk-p-xs);
      justify-content: center;
      align-items: center;
      content: "\e824";
      display: flex;
    }
  }
}

.a2a_button_pinterest {
  padding: 0;

  .a2a_svg {
    &:after {
      content: "\e82e";
    }
  }
}
