/*==============================================================================
// File:        _score.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared review score component - SASS
//============================================================================*/

.zen-score {
  position: relative;
  overflow: hidden;
  display: block;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("container") {
    display: inline-block;
    position: relative;
  }

  @include element("highlighted") {
    z-index: var(--rk-z-index-df);
    color: var(--rk-primary);
    display: inline-block;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
  }

  @include element("muted") {
    color: var(--rk-base-grey-light);
    position: absolute;
    left: 0;
    top: 0;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("size-df") {
    height: 20px;

    .zen-icon,
    i {
      font-size: var(--rk-font-size-xl);
    }

    .zen-score__container {
      height: 20px;
    }
  }

  @include modifier("size-xl") {
    .zen-icon,
    i {
      font-size: 30px;
    }
  }

  @include modifier("centered") {
    display: flex;
    justify-content: center;
  }
}

/*==============================================================================
  // SPPB Shadow Option for review-score--shadow
  //============================================================================*/

.zen-review-score--shadow {
  h6,
  .zen-score__muted,
  .zen-score__percentage {
    text-shadow:
      0 0 5px rgba(0, 0, 0, 30%),
      0 0 10px rgb(0, 0, 0, 10%);
  }
}

// Review Image Styling

.review-more-inner {
  margin-left: 5px;
  background: var(--rk-base-white);
  border: 1px solid var(--rk-base-grey);

  span {
    font-weight: 700;
  }
}
