/*==============================================================================
// File:        _styles.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main styles for the website - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Layout Styles
//----------------------------------------------------------------------------*/

html {
  overflow-x: hidden;
}

main {
  padding: 0;
}

body {
  @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, $font-size-sm, $font-size-df);

  font-family: var(--rk-primary-font);
  font-weight: normal;
  position: relative;
  color: var(--rk-text);

  @include media-breakpoint-down(lg) {
    display: inherit;
  }

  .modal-open {
    overflow: initial;
  }
}

#ot-sdk-btn-floating.ot-floating-button {
  z-index: 1000 !important;
}

.zen-currency-selector {
  background: var(--rk-grey-lightest);
  padding: 35px 0;
  border-top: 1px solid rgba(0, 0, 0, 12%);
  border-bottom: 1px solid rgba(0, 0, 0, 12%);

  @include media-breakpoint-down(lg) {
    border-top: none;
    border-bottom: none;
  }

  .footer-logos {
    width: 100%;
    opacity: .5;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
}

.site-body {
  padding-top: 0;
}

.show-desktop {
  @media (max-width: 991px) {
    display: none;
  }
}

a {
  color: var(--rk-secondary);

  &:hover,
  &:focus {
    color: var(--rk-secondary);
  }
}

p {
  margin-bottom: var(--rk-m-df);
  white-space: normal;
}

hr {
  border-color: var(--rk-base-grey-darker);
}

iframe {
  border: none;
}

.zen-back-to-top {
  background: var(--rk-default-dark);
  z-index: var(--rk-z-index-middle);
  position: fixed;
  display: none;
  bottom: 10px;
  left: 10px;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.zen-overlay {
  background: transparent;

  /*Rollback to clear BG*/
  background: rgba($base-black, .4);
  position: fixed;
  display: none;
  height: 100%;
  z-index: 25;
  width: 100%;
  opacity: 0;
  top: 0;

  &:hover {
    cursor: pointer;
  }
}

/*------------------------------------------------------------------------------
// Example Class Animation for Lazyload
//----------------------------------------------------------------------------*/

.zen-lazy-load {
  display: block;
  height: 1px;
  width: 1px;
}

.in-viewport {
  @include insert-transition(all, .3s, ease, 0s);

  visibility: hidden;
  display: none;
  opacity: 0;
  height: 0;

  @include adjoin("active-viewport") {
    @include insert-transition(all, .3s, ease, 0s);

    visibility: visible;
    display: block;
    height: auto;
    opacity: 1;
  }
}

/*------------------------------------------------------------------------------
// Zen Main Styles
//----------------------------------------------------------------------------*/

.zen-image-shadow img {
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 70%));
}

.zen-flex-center {
  @include flex--center;
}

.zen-flex-end {
  @include flex--end;
}

.zen-heading-light {
  @extend %zen-title--light;
}

.zen-heading-dark {
  @extend %zen-title--dark;
}

.zen-heading-primary {
  @extend %zen-title--primary;
}

.zen-heading-secondary {
  @extend %zen-title--secondary;
}

.zen-text-lead {
  @extend %zen-text--lead;
}

.zen-footer-menu {
  @extend %zen-menu__footer;
}

.zen-text-default {
  color: var(--rk-default-dark);
}

.zen-bg-default {
  background: var(--rk-base-grey);
}

.zen-bg-default-light {
  background: var(--rk-light);
}

.zen-bg-default-dark {
  background: var(--rk-dark);
}

.zen-bg-center {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.zen-valid-error {
  border-color: var(--pink);
  background-color: var(--rk-light);
  color: var(--red);
}

.zen-error-text {
  font-size: var(--rk-font-size-df);
  color: var(--rk-error);
}

.zen-white-bg {
  background: var(--rk-base-white);
}

.zen-emphasized {
  font-style: normal;
}

.zen-no-shadow {
  box-shadow: none;
}

.zen-italic {
  font-style: italic;
}

.zen-bold {
  font-weight: var(--rk-font-bold);
}

.zen-semibold {
  font-weight: var(--rk-font-semi-bold);
}

.zen-semibolder {
  font-weight: var(--rk-font-semi-bolder);
}

.zen-muted {
  color: var(--bs-secondary-color);
}

.zen-weight-normal {
  font-weight: var(--rk-font-normal);
}

.zen-capitalize {
  text-transform: capitalize;
}

.zen-capitalize-first {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.zen-line-through {
  text-decoration: line-through;
}

.zen-hide {
  display: none;
}

.zen-inline-block {
  display: inline-block;
}

.zen-overflow-hidden {
  overflow: hidden;
}

.zen-no-transform {
  text-transform: none;
}

.zen-uppercase {
  text-transform: uppercase;
}

.zen-underline {
  text-decoration: underline;
}

.zen-no-underline {
  text-decoration: none;
}

.zen-nomargin {
  margin: 0;
}

.zen-margin-auto {
  margin: 0 auto;
}

.zen-row {
  margin: 0 -15px;
}

.zen-col {
  float: left;
}

.zen-width-full {
  width: 100%;
}

.zen-width-half {
  width: 50%;
}

.zen-nopad {
  padding: 0;
}

.zen-nopad-top {
  padding-top: 0;
}

.zen-nopad-right {
  padding-right: 0;
}

.zen-nopad-bottom {
  padding-bottom: 0;
}

.zen-nopad-left {
  padding-left: 0;
}

.zen-col-border-left {
  border-left: 1px solid var(--rk-border-colour);

  @include adjoin("hide-lg-down") {
    @include media-breakpoint-down(lg) {
      border: none;
    }
  }

  @include adjoin("hide-md-down") {
    @include media-breakpoint-down(md) {
      border: none;
    }
  }
}

.zen-col-border-right {
  border-right: 1px solid var(--rk-border-colour);

  @include adjoin("hide-lg-down") {
    @include media-breakpoint-down(lg) {
      border: none;
    }
  }

  @include adjoin("hide-md-down") {
    @include media-breakpoint-down(md) {
      border: none;
    }
  }
}

.zen-container-restrict {
  max-width: 920px;
}

.zen-container-restrict-sm {
  max-width: 520px;
}

.zen-container-restrict-md {
  max-width: 720px;
}

.zen-z-index-df {
  z-index: var(--rk-z-index-df) !important;
}

.zen-z-index-top {
  z-index: var(--rk-z-index-top) !important;
}

.zen-z-index-middle {
  z-index: var(--rk-z-index-middle) !important;
}

.zen-z-index-bottom {
  z-index: var(--rk-z-index-bottom) !important;
}

.zen-line-height-df {
  line-height: var(--rk-line-height-df);
}

.zen-line-height-xl {
  line-height: var(--rk-line-height-xl);
}

.zen-line-height-lg {
  line-height: var(--rk-line-height-lg);
}

.zen-line-height-md {
  line-height: var(--rk-line-height-md);
}

.zen-line-height-sm {
  line-height: var(--rk-line-height-sm);
}

.zen-line-height-xs {
  line-height: var(--rk-line-height-xs);
}

/*------------------------------------------------------------------------------
// Login Page Styling
//----------------------------------------------------------------------------*/

.profile {
  padding: var(--rk-p-df) 0;

  .profile-core {
    margin: $m-full-df;
  }
}

.profile-edit {
  padding: var(--rk-p-df) 0;
}

/*------------------------------------------------------------------------------
// Error Page Styling
//----------------------------------------------------------------------------*/

.error-page {
  .jumbotron {
    border-top: 1px solid var(--rk-base-white);
    background: var(--rk-secondary);
  }
}

/*------------------------------------------------------------------------------
// Recapcha Badge Hide
//----------------------------------------------------------------------------*/

.grecaptcha-badge {
  opacity: 0;
}

/*------------------------------------------------------------------------------
// Aito Widget Styling
//----------------------------------------------------------------------------*/

#aito-widget,
.aito-widget {
  max-height: 60px;
  overflow: hidden;
}

/*------------------------------------------------------------------------------
// Hide OSMaps H2
//----------------------------------------------------------------------------*/

.osmap-items h2 {
  display: none;
}

@media (min-width: 992px) {
  .lb-nav {
    .lb-next {
      right: -80px !important;
      position: absolute !important;
      opacity: 1 !important;
    }

    .lb-prev {
      left: -80px !important;
      position: absolute !important;
      opacity: 1 !important;
    }
  }
}

.lightboxOverlay {
  opacity: .9;
}
