/*==============================================================================
// File:        _text.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared text component - SASS
//============================================================================*/

.zen-text {
  white-space: normal;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("lead") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 15px, 16px);

    font-weight: var(--rk-font-semi-bold);
    color: var(--rk-base-black);
  }

  @include modifier("subtitle") {
    @extend h4;

    color: var(--rk-text);
  }

  @include modifier("line-height-lg") {
    line-height: var(--rk-line-height-lg);
  }

  @include modifier("table-price") {
    @include media-breakpoint-down(lg) {
      font-size: var(--rk-font-size-lg);
    }
  }

  @include modifier("table-price-strike") {
    text-decoration: line-through;
    color: var(--rk-danger);
    padding-right: 5px;
  }

  @include modifier("default") {
    color: var(--rk-default);

    @include adjoin("active") {
      font-weight: bold;
    }
  }

  @include modifier("default-bold") {
    line-height: var(--rk-line-height-df);
    font-weight: bold;
    color: var(--rk-text);
  }

  @include modifier("default-dark") {
    color: var(--rk-default-dark);
  }

  @include modifier("default-light") {
    color: var(--rk-default-light);
  }

  @include modifier("default-lighter") {
    color: var(--rk-default-lighter);
  }

  @include modifier("primary") {
    color: var(--rk-primary);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("dark") {
    color: var(--rk-base-black);
  }

  @include modifier("light") {
    color: var(--rk-base-white);
  }

  @include modifier("light-bold") {
    color: var(--rk-base-white);
    font-weight: bold;
  }

  @include modifier("large") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 15px, 17px);

    line-height: var(--rk-line-height-xl);
  }

  @include modifier("large-bold") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 15px, 17px);

    line-height: var(--rk-line-height-sm);
    font-weight: bold;
  }

  @include modifier("availability") {
    font-size: var(--rk-font-size-md);
    vertical-align: middle;
    display: inline-block;
    color: var(--rk-secondary);
    padding: 0;
    margin: 0;

    @include adjoin("active") {
      font-weight: bold;
      color: var(--rk-primary);
    }
  }

  @include modifier("alert") {
    color: var(--rk-danger);
  }

  @include modifier("author") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 16px, 26px);

    line-height: var(--rk-line-height-df);
    color: var(--rk-default);
  }

  @include modifier("price") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 16px, 18px);

    font-weight: var(--rk-font-bold);
    color: var(--rk-primary);
    line-height: 1.15em;
  }

  /*------------------------------------------------------------------------------
  // Letter Space Changes
  //----------------------------------------------------------------------------*/

  @include modifier("letter-space-df") {
    letter-spacing: var(--rk-letter-space-df);
  }

  @include modifier("letter-space-md") {
    letter-spacing: var(--rk-letter-space-md);
  }

  @include modifier("letter-space-lg") {
    letter-spacing: var(--rk-letter-space-lg);
  }

  @include modifier("letter-space-xl") {
    letter-spacing: var(--rk-letter-space-xl);
  }

  /*------------------------------------------------------------------------------
  // Line Height Changes
  //----------------------------------------------------------------------------*/

  @include modifier("line-height-df") {
    line-height: var(--rk-line-height-df);
  }

  @include modifier("line-height-md") {
    line-height: var(--rk-line-height-md);
  }

  @include modifier("line-height-lg") {
    line-height: var(--rk-line-height-lg);
  }

  @include modifier("line-height-xl") {
    line-height: var(--rk-line-height-xl);
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-df") {
    font-size: var(--rk-font-size-df);
  }

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }

  @include modifier("text-h1") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 38px, 48px);
  }

  @include modifier("text-h2") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 32px, 42px);
  }

  @include modifier("text-h3") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 26px, 32px);
  }

  @include modifier("text-h4") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 22px, 26px);
  }

  @include modifier("text-h5") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 20px, 22px);
  }

  @include modifier("text-h6") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 18px, 20px);
  }
}

/*------------------------------------------------------------------------------
// Extend text element & modifiers
//----------------------------------------------------------------------------*/

%zen-text {
  @extend .zen-text;
}

%zen-text--lead {
  @extend .zen-text--lead;
}
