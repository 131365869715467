/*==============================================================================
// File:        _sppb.scss
// Package:     Joomla / Mr Zen
// Synopsis:    SP Page Builder overrides - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Carousel Slider SPPB Adjustments
//----------------------------------------------------------------------------*/

.sppb-carousel-extended {
  .sppb-carousel-extended-item {
    .sppb-testimonial-carousel-item-content {
      @extend %zen-card;

      background: var(--rk-base-white);
      border-color: var(--rk-secondary-light);
      margin-bottom: var(--rk-m-df) !important;
      border-radius: var(--rk-border-radius-df) !important;

      // Remove speech bubble callout
      &:before {
        display: none;
      }
    }

    .sppb-testimonial-carousel-message {
      font-size: var(--rk-font-size-lg);
      color: var(--rk-primary) !important;
    }
  }

  .sppb-carousel-extended-nav-control {
    .nav-control {
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary-light);
      color: var(--rk-base-white);
    }
  }
}

.sppb-modal-selector {
  span {
    border-style: none !important;
  }
}

// Fix Icon for SPPB Accordion Panel (used in Footer Menu)
.sppb-panel-faq {
  .sppb-toggle-direction {
    .fa-chevron-right:before {
      @extend %fontello;

      content: "\f105";
      font-size: var(--rk-font-size-xl);
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;
    }
  }

  .sppb-panel-heading {
    color: var(--rk-base-black);
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, 12%);
    border-bottom: 1px solid rgba(0, 0, 0, 12%);
  }
}
// Accordion Overrides.
.sppb-panel-modern {
  margin-bottom: 0 !important;

  .sppb-panel-heading {
    @extend %zen-btn--accordion-sub;

    margin: 0;

    &:after {
      content: none;
    }

    .sppb-toggle-direction {
      transform: translateY(-50%) rotate(90deg);
    }

    .sppb-panel-title {
      font-size: var(--rk-font-size-lg);
    }

    .fa-chevron-right:before {
      @extend %fontello;

      color: var(--rk-primary);
      content: "\f105";
      font-size: 30px;
    }

    &.active {
      .sppb-toggle-direction {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }

  .sppb-panel {
    .sppb-panel-body {
      @extend %p;

      color: var(--rk-default);
    }
  }
}

//  Extending P Style onto SPPB.
.sppb-addon-text-block p {
  @extend %p;
}

.sppb-panel-modern > .sppb-panel-heading {
  background: var(--rk-primary);
  color: var(--rk-base-white);
}

.sppb-alert-primary {
  background: var(--rk-primary);
  color: var(--rk-base-white);
}

.sppb-progress-bar {
  background: var(--rk-primary);
}

.animated-text-words-wrapper {
  color: var(--rk-primary);
}

.alert-warning {
  color: var(--rk-warning);
}

.sppb-blocknumber-number {
  background-color: var(--rk-primary);
}

.sppb-addon-flickr .sppb-flickr-gallery li a:before {
  background: var(--rk-secondary);
}

.sppb-addon-flickr .sppb-flickr-gallery li a:hover:before {
  background: transparent;
}

.image-effect-zoom-in {
  cursor: pointer;
}

.pricelist-left-image {
  img {
    max-width: 100%;
  }
}

// Fix SP Page Builder Breakpoint Issue
@media screen and (max-width: 768px) and (min-width: 768px) {
  .sppb-addon-image-content {
    .sppb-col-sm-6 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

// Fix for adding a new row - SPPB front-end edit mode
.sppb-row-bottom-new-row .open .sp-pagebuilder-dropdown-row-layouts {
  display: block;
}

// Zen SP Page Builder Overrides

// Example changing the standard spacing to 20px
// Setting all items to maximum width up to 1440px

// Margin top to give space for breadcrumbs
html.homepage #sp-page-builder {
  margin-top: 0 !important;
}

html.subpage #sp-page-builder {
  margin-top: 125px;
}

.sppb-row-container {
  padding-right: 0;
  padding-left: 0;

  @include media-breakpoint-up(sm) {
    max-width: 760px;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    max-width: 900px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1100px;
    width: 100%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1440px !important;
    width: 100%;
  }
}

.sp-pagebuilder-row,
.sppb-row {
  margin-right: -15px;
  margin-left: -15px;

  @include adjoin("sppb-no-gutter") {
    margin-right: 0;
    margin-left: 0;
  }
}

.sppb-col,
.sppb-col-auto,
.sppb-col-lg,
.sppb-col-lg-1,
.sppb-col-lg-10,
.sppb-col-lg-11,
.sppb-col-lg-12,
.sppb-col-lg-2,
.sppb-col-lg-3,
.sppb-col-lg-4,
.sppb-col-lg-5,
.sppb-col-lg-6,
.sppb-col-lg-7,
.sppb-col-lg-8,
.sppb-col-lg-9,
.sppb-col-lg-auto,
.sppb-col-md,
.sppb-col-md-1,
.sppb-col-md-10,
.sppb-col-md-11,
.sppb-col-md-12,
.sppb-col-md-2,
.sppb-col-md-3,
.sppb-col-md-4,
.sppb-col-md-5,
.sppb-col-md-6,
.sppb-col-md-7,
.sppb-col-md-8,
.sppb-col-md-9,
.sppb-col-md-auto,
.sppb-col-sm,
.sppb-col-sm-1,
.sppb-col-sm-10,
.sppb-col-sm-11,
.sppb-col-sm-12,
.sppb-col-sm-2,
.sppb-col-sm-3,
.sppb-col-sm-4,
.sppb-col-sm-5,
.sppb-col-sm-6,
.sppb-col-sm-7,
.sppb-col-sm-8,
.sppb-col-sm-9,
.sppb-col-sm-auto,
.sppb-col-xl,
.sppb-col-xl-1,
.sppb-col-xl-10,
.sppb-col-xl-11,
.sppb-col-xl-12,
.sppb-col-xl-2,
.sppb-col-xl-3,
.sppb-col-xl-4,
.sppb-col-xl-5,
.sppb-col-xl-6,
.sppb-col-xl-7,
.sppb-col-xl-8,
.sppb-col-xl-9,
.sppb-col-xl-auto,
.sppb-col-xs-1,
.sppb-col-xs-10,
.sppb-col-xs-11,
.sppb-col-xs-12,
.sppb-col-xs-2,
.sppb-col-xs-3,
.sppb-col-xs-4,
.sppb-col-xs-5,
.sppb-col-xs-6,
.sppb-col-xs-7,
.sppb-col-xs-8,
.sppb-col-xs-9 {
  padding-right: var(--rk-p-df);
  padding-left: var(--rk-p-df);
}

// Button Module and Class Overrides - Mr Zen Customisations

.mobile-w-100 {
  @include media-breakpoint-down(md) {
    .sppb-btn {
      width: 100%;
    }
  }
}

.sppb-btn {
  @extend %zen-btn;

  @include adjoin("sppb-btn-default") {
    @extend %zen-btn;
  }

  @include adjoin("sppb-btn-primary") {
    @extend %zen-btn--outlined-primary;
  }

  @include adjoin("sppb-btn-secondary") {
    @extend %zen-btn--secondary;
  }

  @include adjoin("sppb-btn-success") {
    @extend %zen-btn--outlined-secondary;
  }

  @include adjoin("sppb-btn-info") {
    @extend %zen-btn--outlined-opaque;
  }

  @include adjoin("sppb-btn-warning") {
    @extend %zen-btn--warning;
  }

  @include adjoin("sppb-btn-danger") {
    @extend %zen-btn--danger;
  }

  @include adjoin("sppb-btn-dark") {
    @extend %zen-btn--dark;
  }

  @include adjoin("sppb-btn-link") {
    @extend %zen-btn--link;
    @extend %zen-btn--text-xs;
  }

  .fa,
  i {
    @extend %zen-icon;
    @extend %zen-icon--text-xxs;
    @extend %zen-icon--secondary;
  }
}

.sppb-magnific-popup {
  padding: 12px var(--rk-p-df) !important;
}

// Slideshow Module - Mr Zen Customisations
.sppb-sp-slider-button .sp-slider-btn-text {
  @extend %zen-btn;
}

.sp-slider .sp-dots,
.sp-slider .sp-nav-control {
  @include media-breakpoint-down(md) {
    height: 40px;
  }
}

.sppb-addon-sp-slider .sp-item .sp-background:after {
  background: rgba(var(--rk-base-black), .1);
}

div.sp-slider .sp-nav-control .nav-control {
  background: rgba(var(--rk-base-white), .15);
}

div[data-arrow-content="icon_only"].sp-slider .sp-nav-control .nav-control {
  font-size: 30px;
  height: 60px;
  width: 60px;
}

div[data-arrow-content="icon_only"] .sp-nav-control .nav-control i {
  line-height: 58px;
}

.sp-slider .sp-nav-control .nav-control.next-control i {
  margin: 0 -2px 0 0;
  right: 5px;
}

.sp-slider .sp-nav-control .nav-control.prev-control i {
  margin: 0 0 0 -2px;
  left: 5px;
}

.sp-dots {
  ul li:before {
    content: none;
  }
}

.sppb-featurebox-wrapper {
  .zen-cta--featurebox {
    aspect-ratio: unset;
    height: 400px;
    margin-bottom: 30px !important;

    .zen-cta__featurebox-content p {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }

  .zen-cta--rectangle .zen-cta__featurebox-image {
    height: 200%;
    width: 200%;
    max-height: 400px;
  }
}

/*------------------------------------------------------------------------------
// Carousel Pro Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-carousel-pro-text,
.sppb-carousel-text {
  justify-content: center;
  flex-direction: column;
  display: flex;

  h1,
  h2,
  h3,
  div {
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 30%);
    color: var(--rk-base-white);
  }

  div {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 48px, 112px);
    @include fluid-prop(line-height, $viewport-xs, $viewport-xl, 48px, 112px);

    font-weight: var(--rk-font-bold);
  }

  .sppb-carousel-pro-content {
    color: var(--rk-base-white);
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  .sppb-btn {
    margin: var(--rk-m-xl) auto 0;
    max-width: max-content;
    width: auto;
  }
}

.sppb-home-slider {
  .sppb-btn {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 45px;
    background: none;
    text-shadow: 0 0 10px rgba(0, 0, 0, 70%);
    border: 2px solid var(--rk-base-white);

    @media (max-width: 991px) {
      right: 110px;
      bottom: 35px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.sppb-carousel-pro {
  .sppb-container.sppb-carousel-pro-arrow-bottom-right {
    bottom: 50px;
    right: 50px;
    margin: auto 0 0 auto;

    @media (max-width: 991px) {
      bottom: 20px;
      right: 20px;
    }
  }

  .sppb-carousel-arrow {
    border: none;
  }

  .sppb-col-sm-12.sppb-col-xs-12 {
    position: unset;
  }

  .fa-chevron-left:before {
    @extend %fontello;

    content: "\f104";
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 70%);
  }

  .fa-chevron-right:before {
    @extend %fontello;

    content: "\f105";
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 70%);
  }

  .sppb-item.sppb-item-has-bg .sppb-carousel-item-inner {
    padding: var(--rk-p-lg);

    @include media-breakpoint-down(md) {
      padding: var(--rk-p-sm);
    }
  }

  .sppb-carousel-pro-inner-content {
    border: 3px solid var(--rk-border-light);
  }
}

/*------------------------------------------------------------------------------
// Image Carousel Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-carousel-extended-dots ul li span {
  background: var(--rk-primary);
}

/*------------------------------------------------------------------------------
// Image Layout Module - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-addon-image-layouts {
  .sppb-image-layouts-inline {
    .sppb-addon-image-layout-caption {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 30px, 40px);

      border-left: 4px solid var(--rk-primary);
      font-weight: var(--rk-font-bold);
      line-height: 120%;
      background: none;
      max-width: 500px;
    }
  }
}

/*------------------------------------------------------------------------------
// Title Style Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-sp-slider-title {
  text-shadow: 1px 1px 10px var(--rk-base-black);
  margin: var(--rk-m-full-df);

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

.sppb-sp-slider-text {
  text-shadow: 1px 1px 5px var(--rk-base-black);

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

h1.sppb-sp-slider-title {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 38px, 48px);
}

.zen-heading-light .sppb-addon-title {
  @extend %zen-title--light;
}

.zen-heading-dark .sppb-addon-title {
  @extend %zen-title--dark;
}

.zen-heading-primary .sppb-addon-title {
  @extend %zen-title--primary;
}

.zen-heading-secondary .sppb-addon-title {
  @extend %zen-title--secondary;
}

.zen-footer .sppb-addon-title {
  @extend %zen-title--light;
}

.zen-capitalize .sppb-addon-title {
  text-transform: capitalize;
}

.zen-col-bg-light .horizontal-slider__col {
  @include media-breakpoint-down(lg) {
    background: var(--rk-grey-lightest);
    padding: 30px;
    border: none;
  }
}

.zen-col-border .sppb-row-column:not(:last-child) {
  border-right: 1px solid var(--rk-border-colour);
}

.zen-last-wrap-x-axis-fixed .sppb-column-addons .sppb-addon-wrapper {
  &:nth-child(2) {
    right: 0;
    left: 0;
  }

  &:nth-child(3),
  &:nth-child(4) {
    width: 100%;
  }
}

.sppb-addon-header {
  position: relative;
}

/*------------------------------------------------------------------------------
// Contact Form Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-form-group .sppb-form-control,
.sppb-form-control {
  @extend %zen-input;
}

/*------------------------------------------------------------------------------
// Prevent line through PNG images
//----------------------------------------------------------------------------*/
.sppb-addon-single-image-container {
  a {
    border-bottom: none !important;
  }
}

/*------------------------------------------------------------------------------
// Tab Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-tab {
  @extend %zen-tab;

  .sppb-nav {
    justify-content: center;
    border: none;
  }

  .sppb-nav-modern > li {
    a {
      @extend %zen-tab__link;

      padding: 10px 20px !important;
      border: none !important;
      color: var(--rk-primary) !important;

      &:hover,
      &:focus {
        color: var(--rk-base-black) !important;
      }
    }

    @include adjoin("active") {
      a {
        @extend %zen-tab__link--active;

        color: var(--rk-base-white) !important;

        &:after {
          border-top: 12px solid var(--rk-primary);
          border-right: 12px solid rgba(0, 0, 0, 0%);
          border-left: 12px solid rgba(0, 0, 0, 0%);
          bottom: -10px;
          right: 50%;
          transform: translate(50%, 0);
          height: 0;
          width: 0;
        }
      }
    }
  }

  @include adjoin("sppb-lines-tab") {
    @extend %zen-tab--bordered;

    .sppb-nav-lines > li {
      a {
        @extend %zen-tab__link;

        color: var(--rk-text);
      }

      @include adjoin("active") {
        a {
          @extend %zen-tab__link--active;

          border-bottom: 2px solid $primary !important;
          background: var(--rk-base-white);

          &:after {
            border-bottom: 6px solid $primary;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-top: none;
            bottom: -1px;
            right: 43%;
          }
        }
      }
    }
  }

  .sppb-tab-content .sppb-tab-pane {
    @extend %zen-tab__content-pane;
  }
}

/*------------------------------------------------------------------------------
// Content SPPB Adjustments
//----------------------------------------------------------------------------*/

.zen-main {
  .zen-holiday__intro-description,
  .sppb-content-holder,
  .sppb-addon-content {
    a:not(.sppb-btn, .zen-link, .zen-btn) {
      @extend %zen-link;
      @extend %zen-link--in-content;
    }
  }
}

/*------------------------------------------------------------------------------
// Table Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-addon-table-main tbody tr {
  background: var(--rk-light);
}

/*------------------------------------------------------------------------------
// Remove SPPB 'z-index' CSS Class '.zen-remove-zindex'
//----------------------------------------------------------------------------*/

#sp-page-builder .page-content .sppb-section.zen-remove-zindex,
.mod-sppagebuilder .sppb-section.zen-remove-zindex,
#sp-page-builder .sppb-section.zen-remove-zindex {
  z-index: auto;
}

.zen-remove-zindex .sppb-column-addons,
.zen-remove-zindex .sppb-column {
  z-index: auto;
}

/*------------------------------------------------------------------------------
// Image Content override
//----------------------------------------------------------------------------*/

.sppb-addon-image-content {
  .sppb-content-holder h1 a,
  .sppb-conent-holder h2 a,
  .sppb-content-holder h3 a,
  .sppb-content-holder h4 a,
  .sppb-content-holder h5 a,
  .sppb-content-holder h6 a {
    border-bottom: none !important;
  }
}

/*------------------------------------------------------------------------------
// Blog Flash SPPB Adjustments
//----------------------------------------------------------------------------*/

.sppb-addon-article {
  @extend %zen-article;
}

.sppb-article-img-wrap {
  @extend %zen-article__image;
}

.sppb-article-info-wrap {
  @extend %zen-article__content;
}

.sppb-readmore {
  @extend %zen-btn;
}

.sppb-addon-articles .sppb-col-sm-4 {
  @include media-breakpoint-down(md) {
    margin-top: var(--rk-m-xl);
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/*------------------------------------------------------------------------------
// Top Notice SPPB Text Adjustments
//----------------------------------------------------------------------------*/

.sppb-addon.sppb-addon-text-block.top-notice-text {
  p {
    text-align: center;
    color: var(--rk-base-white);
    margin-bottom: 0;
    padding: var(--rk-p-sm);
    font-weight: var(--rk-font-bold);
  }

  a {
    color: var(--rk-base-white);
  }
}
