/*==============================================================================
// File:        _accordion.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared accordion component - SASS
//============================================================================*/

.zen-accordion {
  padding: 0;

  .accordion-item:first-child * {
    border-top: none;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("dashed") {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 20px;
      left: 80px;
      width: 1px;
      border-right: 3px dashed var(--rk-primary);
      transform: translateX(0%);
      height: calc(100% - 70px);
    }

    @include adjoin("external") {
      &:before {
        top: 45px;
        height: calc(100% - 45px);
      }
    }

    .accordion-item {
      margin-left: 0;
    }

    @include media-breakpoint-down(lg) {
      border-left: 3px dashed var(--rk-primary);

      &:before {
        display: none;
      }

      .accordion-item {
        margin-left: -3px;
        padding-bottom: 50px;
      }
    }
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("btn-main") {
    @extend %zen-btn--accordion-main;
  }

  @include element("btn-sub") {
    @extend %zen-btn--accordion-sub;

    background: var(--rk-secondary);
  }

  @include element("btn-itinerary") {
    @extend %zen-btn--accordion-itinerary;

    &:after {
      @extend %fontello;

      transform: rotate(90deg);
      content: "\f105";
      position: absolute;
      right: 50px;
      font-size: 30px;
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &[aria-expanded="true"] .show-details {
      display: none;
    }

    .day-number {
      font-size: 13px;
      letter-spacing: 1.4px;
      font-weight: var(--rk-font-semi-bolder);
      background-color: var(--rk-primary);
      color: var(--rk-base-white);
      padding: 12px  var(--rk-p-md) var(--rk-p-sm);
      text-transform: uppercase;
      text-align: center;
      width: 100px;
      position: relative;
      line-height: 1em;
    }
  }

  // last child
  :last-child {
    .zen-accordion__btn-itinerary:before {
      height: 75%;

      @include media-breakpoint-down(lg) {
        height: 90%;
      }
    }
  }

  @include element("itinerary-content") {
    padding: 0 var(--rk-p-md);
    border-bottom: none;
    margin: 0;

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid var(--rk-base-grey);
    }
  }

  @include element("content") {
    padding: 0 var(--rk-p-md);
    margin: 0;

    .zen-text,
    p,
    ul li,
    ol li {
      font-size: var(--rk-font-size-md);
      color: var(--rk-default);
    }
  }

  @include element("intro-content") {
    margin: 0;
    padding: 0;

    .zen-text,
    p,
    ul li,
    ol li {
      font-size: var(--rk-font-size-md);
      color: var(--rk-dark);
    }
  }

  .row {
    position: relative;
  }

  .dates {
    .zen-text {
      font-size: var(--rk-font-size-md);
    }
  }

  .zen-pill {
    border-radius: var(--rk-border-radius-lg);
    background: var(--rk-base-grey-lighter);
    font-size: var(--rk-font-size-md);
    padding: 8px var(--rk-p-md);
  }
}
