/*==============================================================================
// File:        _image.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared image component - SASS
//============================================================================*/

.zen-image {
  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("person") {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    max-width: 450px;
  }
}
