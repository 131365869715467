/*==============================================================================
// File:        _card.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared card component - SASS
//============================================================================*/

.zen-card {
  @include insert-shadow(0, 0, 15px, -2px, var(--rk-box-shadow));

  border: 1px solid var(--rk-border-colour);
  background: var(--rk-base-white);
  border-radius: 0;
  margin: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("image-container") {
    padding: var(--rk-p-df);
  }

  @include element("image") {
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 0;

    @include modifier("max-height") {
      max-height: 285px;
      overflow: hidden;
    }
  }

  @include element("title") {
    margin: var(--rk-m-md) 0;
  }

  @include element("header") {
    background: var(--rk-light);
    border-radius: 0;
    border: none;
  }

  @include element("body") {
    padding: var(--rk-p-df);
  }

  @include element("icon") {
    color: var(--rk-primary);
    font-size: 16px;
  }

  @include element("interactive") {
    padding: 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-xs);
    min-height: 32px;
  }

  @include element("favourite") {
    color: var(--rk-default-lighter);
    user-select: none;

    &:hover,
    &:focus {
      color: var(--rk-default);
    }

    @include adjoin("fav-active") {
      .icon-heart,
      .zen-icon {
        color: var(--rk-primary);

        &:before {
          content: "\e85e" !important;
          font-size: 28px;
          position: relative;
        }

        &:hover,
        &:focus {
          color: var(--rk-default);
        }
      }
    }
  }

  @include element("content") {
    border-top: 1px solid var(--rk-secondary);
    padding: var(--rk-p-sm) 0;

    ul {
      @extend %zen-list;
      @extend %zen-list--default;
    }
  }

  @include element("description") {
    padding: var(--rk-p-df);
  }

  @include element("info") {
    border-top: 1px solid var(--rk-border-colour);
    flex-direction: column;
    text-transform: capitalize;
    justify-content: center;
    padding: var(--rk-p-df);
    display: flex;
    max-height: 97px;

    .info-item {
      text-align: center;
      padding: 0 var(--rk-p-md);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:not(:first-child) {
        border-left: 1px solid var(--rk-border-colour);
      }
    }
  }

  @include element("price") {
    justify-content: space-between;
    border: 1px solid var(--rk-primary);
    background: var(--rk-primary);
    margin: 0 var(--rk-m-df);
    padding: var(--rk-p-df);
    display: flex;
  }

  @include element("footer") {
    background: var(--rk-base-grey-lightest);
    padding: var(--rk-p-df);
    border: none;

    @media (max-width: 767px) {
      .without-flights,
      .with-flights {
        .zen-text {
          font-size: 13px;
        }

        p {
          font-size: 11px;
        }
      }
    }
  }

  @include element("heading") {
    min-height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  @include element("review-title") {
    min-height: 45px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  @include element("highlight-tags") {
    position: absolute;
    top: 20px;
    right: 0;
  }

  @include element("highlight-tag") {
    background: var(--rk-primary);
    color: white;
    text-align: center;
    padding: 8px 12px 6px;
    margin-bottom: 10px;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("horizontal") {
    max-width: unset !important;

    @media (max-width: 991px) {
      .zen-icon {
        font-size: var(--rk-icon-size-sm);
      }

      .grade-image {
        height: 22px !important;
      }
    }
  }

  @include modifier("review") {
    box-shadow: none;
    border: none;

    @include media-breakpoint-down(md) {
      background: var(--rk-grey-lightest);
    }

    .zen-card__content {
      border-top: none;
    }

    .card-title {
      display: flex;
      align-items: center;
    }
  }

  @include modifier("related") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    margin: 0 0 var(--rk-m-xl);
    max-width: 448px;

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }

    .grade-image {
      height: 32px;
    }

    .zen-card__footer {
      min-height: 112.4px;
    }
  }

  @include modifier("search") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("exclusive") {
    border: 1px solid var(--rk-primary);
  }

  @include modifier("note") {
    padding: var(--rk-p-lg) var(--rk-p-xl);
  }

  @include modifier("full-height") {
    /*Rollback Display*/
    display: block;
    display: grid;
    height: 100%;
  }

  @include modifier("dates-prices") {
    border: 6px solid var(--rk-primary);
    background: var(--rk-base-white);
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-card {
  @extend .zen-card;
}

%zen-card__image {
  @extend .zen-card__image;
}

%zen-card__title {
  @extend .zen-card__title;
}

%zen-card__header {
  @extend .zen-card__header;
}

%zen-card__body {
  @extend .zen-card__body;
}

%zen-card__info {
  @extend .zen-card__info;
}

%zen-card__footer {
  @extend .zen-card__footer;
}
