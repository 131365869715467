/*==============================================================================
// File:        _icon.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared icon component - SASS
//============================================================================*/

.zen-icon {
  font-size: var(--rk-icon-size-df);
  padding: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    color: var(--rk-text);
  }

  @include modifier("primary") {
    color: var(--rk-primary);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("light") {
    color: var(--rk-base-white);
  }

  @include modifier("dark") {
    color: var(--rk-base-black);
  }

  @include modifier("green") {
    color: var(--rk-success);
  }

  @include modifier("mint") {
    color: var(--rk-mint);
  }

  @include modifier("red") {
    color: var(--rk-error);
  }

  @include modifier("orange") {
    color: var(--rk-warning);
  }

  @include modifier("grey") {
    color: var(--rk-base-grey-darker);
  }

  @include modifier("helper") {
    font-size: var(--rk-font-size-xxs);
    margin: 0 0 0 -1px;
    position: relative;
    color: var(--rk-text);
    top: -3px;
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xxl") {
    font-size: var(--rk-icon-size-xxl);
  }

  @include modifier("text-xl") {
    font-size: var(--rk-icon-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-icon-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-icon-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-icon-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-icon-size-xs);
  }

  @include modifier("text-xxs") {
    font-size: var(--rk-icon-size-xxs);
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-icon {
  @extend .zen-icon;
}

%zen-icon--secondary {
  @extend .zen-icon--secondary;
}

%zen-icon--text-xxs {
  @extend .zen-icon--text-xxs;
}
