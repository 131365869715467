/*==============================================================================
// File:        _gallery.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared gallery component - SASS
//============================================================================*/

.zen-gallery {
  border-bottom: 1px solid var(--rk-default);
  margin: 0 auto var(--rk-m-xl);
  padding: 0 0 var(--rk-p-md);
  max-width: 750px;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("large") {
    margin: var(--rk-m-xl) auto;
    max-width: 100%;
    border: none;
  }

  @include modifier("fade-transition") {
    .carousel-item {
      transition-property: opacity;
      transition-duration: .9s;
      opacity: 0;
    }

    .carousel-item-prev.carousel-item-right,
    .carousel-item-next.carousel-item-left,
    .carousel-item.active {
      opacity: 1;
    }

    .active.carousel-item-right,
    .active.carousel-item-left {
      opacity: 0;
    }

    .active.carousel-item-left,
    .active.carousel-item-prev,
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transform: translate3d(0, 0, 0);
      transform: translateX(0);
    }
  }
}
