/*==============================================================================
// File:        _tour-search.scss
// Package:     Tour Search UI - Vue3 - RezKit
// Synopsis:    Tour search style overrides - SASS
//============================================================================*/

.rk-select {
  background-image: url("/templates/zenbasej4template/images/sys-dropdown.svg");
  border: 1px solid var(--rk-border-colour);
}

.rkts-sort-by {
  --rkts-select-message: "Sort by";

  &:before {
    font-weight: var(--rk-font-normal);
  }

  @include element("drop-down") {
    --rkts-message-indent: 75px;

    padding-right: 30px;
    font-weight: var(--rk-font-bold);
  }
}

.rkts-search-box {
  background: var(--rk-base-grey-lightest);
  margin-bottom: 0;

  &:before {
    font-size: var(--rk-icon-size-sm);
    content: "\e81d";
  }

  @include modifier("primary") {
    box-shadow: 0 0 20px rgba(0, 0, 0, 30%);

    --rk-base-grey-dark: var(--rk-default-lighter);

    background: var(--rk-base-white);
    max-width: 700px;
    margin: 0 auto;

    &:before {
      content: none;
    }
  }

  .rk-input--search-box {
    border: none;
    color: var(--rk-text);
    font-size: 16px;

    &::-webkit-input-placeholder {
      overflow: visible;
      color: var(--rk-text);
    }

    &:-ms-input-placeholder {
      overflow: visible;
      color: var(--rk-text);
    }

    &::placeholder {
      overflow: visible;
      color: var(--rk-text);
    }

    &:not(.layout-primary) {
      padding-left: var(--rk-p-xxl);
    }

    @include adjoin("layout-primary") {
      --rkts-min-height: 74px;

      font-size: var(--rk-font-size-lg);

      @include media-breakpoint-down(md) {
        font-size: var(--rk-font-size-sm);
        min-height: 64px;
      }
    }
  }

  @include element("submit") {
    padding: var(--rk-p-sm) 12px;

    .icon-sys-search-1:before {
      content: "\e81d";
    }

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }
}

.rk-list--suggestion {
  border: none;

  li:not(.title) {
    margin: 0 var(--rk-p-sm) var(--rk-p-sm) 0;
    display: inline-block;
    line-height: 37px;

    .suggestion-label {
      border: 2px solid var(--rk-border-colour);
      padding: var(--rk-p-sm) var(--rk-p-df);
      color: var(--rk-base-black);

      &:hover,
      &:focus {
        border-color: var(--rk-primary);
        color: var(--rk-base-black);
        font-weight: normal;
      }
    }
  }
}

.rk-filters--horizontal,
.rk-filters {
  --rkts-justify-content: start;
  --rkts-flex-flow: row wrap;

  .dropdown-menu {
    --bs-dropdown-border-radius: 0;

    @include insert-shadow(0, 7px, 14px, 0, $shadow-light);

    border: 1px solid var(--rk-border-colour);
    border-top: 3px solid var(--rk-primary);
  }
}

.rk-filters__facet {
  padding: var(--rk-p-df) var(--rk-p-df) var(--rk-p-lg);
  font-size: var(--rk-font-size-df);
  min-width: 300px;

  &:not(.allow-overflow) {
    max-height: 55vh;
    overflow: auto;
  }
}

.rkts-refine-tag {
  margin: 0 var(--rk-m-sm) var(--rk-m-xs) 0;
  padding: 17px var(--rk-p-df);
  align-items: baseline;
  line-height: 1em;

  @include element("icon") {
    &:before {
      content: "\e822";
    }
  }
}

.rkts-list-checkbox {
  @include element("label") {
    margin: 3px 0 0;
  }

  @include element("choice") {
    --rkts-inner-size: 12px;

    border: 1px solid $border-colour-dark;
    border-radius: 0;

    @include adjoin("active") {
      &:before {
        --rkts-content: "\e83d";

        color: var(--rk-primary);
        justify-content: center;
        align-items: center;
        background: none;
        font-size: 11px;
        display: flex;
        height: 14px;
        width: 14px;
      }
    }
  }
}

.rkts-collapsible-list {
  --rkts-collapse-icon: "\f107";

  @include element("title") {
    .rkts-list-checkbox__container,
    .rkts-list-checkbox {
      margin: 0;
    }

    &:after {
      color: var(--rk-primary);
    }
  }

  @include element("content") {
    padding-left: var(--rk-p-lg);
  }
}

.rkts-descriptive-list {
  @include element("title") {
    font-weight: var(--rk-font-semi-bolder);

    .rkts-list-checkbox__container,
    .rkts-list-checkbox {
      margin: 0;
    }
  }

  @include element("line-break") {
    border-top: 1px solid var(--rk-border-colour);
    opacity: 1;
  }
}

.rkts-range-slider {
  max-width: 400px;
}

.rk-results {
  column-gap: 24px;

  @include modifier("fluid") {
    --rkts-justify-content: center;

    @include media-breakpoint-down(lg) {
      --rkts-justify-content: center;
    }
  }
}

.rk-btn--clear {
  --rkts-align-items: end;

  .icon-sys-close:before {
    font-size: var(--rk-font-size-xl);
    content: "\e822";
  }

  @include adjoin("outlined") {
    @extend %zen-btn--outlined-primary;

    padding: var(--rk-p-df) var(--rk-p-df);
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: var(--rk-letter-space-md);

    .icon-sys-close:before {
      content: none;
    }
  }
}

.rkts-list-pagination .Pagination {
  .Page {
    margin: 0 var(--rk-m-sm) 0 0;
    font-weight: var(--rk-font-bold);
    color: var(--rk-base-black);
    border: none;

    @include adjoin("Page-active") {
      font-weight: var(--rk-font-bold);
    }
  }

  .PaginationControl {
    border: 2px solid var(--rk-base-grey-dark);
    margin: 0 var(--rk-m-df) 0 0;
    justify-content: center;
    align-items: center;
    min-height: 55px;
    min-width: 55px;
    max-width: 55px;

    @include media-breakpoint-down(lg) {
      min-height: 35px;
      min-width: 35px;
      max-width: 35px;
    }

    svg {
      fill: var(--rk-base-grey-dark);
    }
  }
}
