/*==============================================================================
// File:        _list.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared list component - SASS
//============================================================================*/

.zen-list {
  padding: 0;
  margin: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("no-type") {
    list-style-type: none;
  }

  @include modifier("default") {
    padding: 0 0 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-df);
    align-items: center;
    position: relative;

    li {
      padding: 0 0 var(--rk-p-xs) var(--rk-p-xs);
      margin: 0 0 var(--rk-m-xs);
      align-items: center;
      list-style: none;
      display: flex;

      &:before {
        position: absolute;
        color: var(--rk-primary);
        font-weight: bold;
        content: "\2022";
        font-size: 26px;
        margin: 0;
        left: 0;
      }
    }
  }

  @include modifier("dark") {
    padding: 0 0 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-df);
    align-items: center;
    position: relative;

    li {
      padding: 0 0 var(--rk-p-xs) var(--rk-p-xs);
      color: var(--rk-base-black);
      margin: 0 0 var(--rk-m-xs);
      align-items: center;
      list-style: none;
      display: flex;

      &:before {
        position: absolute;
        color: var(--rk-base-black);
        font-weight: bold;
        content: "\2022";
        font-size: 12px;
        margin: 0;
        left: 0;
      }
    }
  }

  @include modifier("square-primary") {
    padding: 0 0 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-df);
    align-items: center;
    position: relative;

    ul {
      padding: 0;
    }

    li {
      padding: 0 0 var(--rk-p-xs) var(--rk-p-xs);
      margin: 0 0 var(--rk-m-xs);
      align-items: start;
      list-style: none;
      display: flex;

      &:before {
        font-size: var(--rk-font-size-lg);
        position: absolute;
        color: var(--rk-primary);
        font-weight: bold;
        content: "■";
        margin: -5px 0 0;
        left: 0;
      }
    }
  }

  @include modifier("underline") {
    align-items: start;
    position: relative;
    padding: 0;
    margin: 0;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      border-bottom: 2px solid var(--rk-grey-lightest);
      font-size: var(--rk-font-size-lg);
      margin: 0 0 var(--rk-m-xs);
      padding: var(--rk-p-xs) 0;
      list-style: none;
      display: flex;
    }
  }

  @include modifier("border-bottom") {
    align-items: start;
    position: relative;
    padding: 0;
    margin: 0;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      border-bottom: 2px solid var(--rk-grey-lightest);
      font-size: var(--rk-font-size-lg);
      margin: 0 0 var(--rk-m-xs);
      padding: var(--rk-p-xs) 0;
      list-style: none;
      display: flex;

      a {
        font-weight: var(--rk-font-semi-bold);
      }
    }
  }

  @include modifier("default-ticks") {
    padding: 0 0 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-xl);
    position: relative;

    ul {
      padding-left: var(--rk-p-sm);
      list-style: none;
    }

    li {
      padding: 0 0 var(--rk-p-sm) var(--rk-p-sm);
      margin: 0 0 var(--rk-m-xs);
      color: var(--rk-secondary);

      &::marker {
        display: none;
      }

      &:before {
        @extend %fontello;

        font-size: var(--rk-font-size-md);
        color: var(--rk-primary);
        position: absolute;
        content: "\e874";
        margin: 8px 0 0;
        left: 0;
      }
    }
  }

  @include modifier("default-crosses") {
    padding: 0 0 0 var(--rk-p-df);
    margin: 0 0 var(--rk-m-xl);
    position: relative;
    list-style: none;

    ul {
      padding-left: var(--rk-p-sm);
      list-style: none;
    }

    li {
      padding: 0 0 var(--rk-p-sm) var(--rk-p-sm);
      margin: 0 0 var(--rk-m-xs);
      color: var(--rk-secondary);
      list-style-type: none;

      &::marker {
        display: none;
      }

      &:before {
        @extend %fontello;

        font-size: var(--rk-font-size-md);
        color: var(--rk-primary);
        position: absolute;
        margin: 8px 0 0;
        content: "\e84f";
        left: 0;
      }
    }
  }

  @include modifier("inline") {
    li {
      margin: 0 var(--rk-list-m-df) 0 0;
      display: inline-block;
    }
  }

  @include modifier("space-between") {
    justify-content: space-between;
    align-items: center;
    display: flex;

    li {
      margin: 0 var(--rk-list-m-xl) 0 0;
      display: inline-block;
    }
  }

  /*------------------------------------------------------------------------------
	// Standard menu list item
	//----------------------------------------------------------------------------*/

  @include modifier("menu") {
    text-transform: none;
    flex-direction: row;
    height: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0;
    }

    @include adjoin("top-menu") {
      .nav-link:after {
        font-size: var(--rk-font-size-xs);
        font-weight: var(--rk-font-normal);
        color: var(--rk-default);
        right: 5px;
        top: 12px;
      }
    }

    @include adjoin("menu-footer") {
      li {
        justify-content: start;
      }
    }
  }

  @include modifier("menu-dropdown") {
    text-transform: none;

    li {
      display: block;
      margin: 0;
    }
  }

  @include modifier("menu-main") {
    justify-content: end;
    display: flex;
    height: 50px;

    li {
      align-items: center;
      margin: 0 1px 0 0;
      display: flex;
    }
  }

  @include modifier("menu-sub") {
    @include insert-shadow(0, 0, 10px, -2px, var(--rk-shadow));

    background: var(--rk-base-white);
    color: var(--rk-primary);
    padding: var(--rk-p-sm);
    border-radius: 0;
    min-width: 200px;
    border: none;
    margin: 0;

    &:before {
      @include pseudo;

      border-bottom: 10px solid var(--rk-base-white);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      top: -10px;
      height: 0;
      left: 30%;
      width: 0;
    }

    li {
      border-bottom: 1px solid var(--rk-primary);
      display: flex;

      &:last-child {
        border: none;
      }
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/
  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend list element & modifiers
//----------------------------------------------------------------------------*/

%zen-list {
  @extend .zen-list;
}

%zen-list--default {
  @extend .zen-list--default;
}

%zen-list--default-ticks {
  @extend .zen-list--default-ticks;
}

%zen-list--default-crosses {
  @extend .zen-list--default-crosses;
}

%zen-list--square-primary {
  @extend .zen-list--square-primary;
}

%zen-list--inline {
  @extend .zen-list--inline;
}

%zen-list--space-between {
  @extend .zen-list--space-between;
}

%zen-list--menu-main {
  @extend .zen-list--menu-main;
}

%zen-list--menu-sub {
  @extend .zen-list--menu-sub;
}
