/*==============================================================================
// File:        _hero.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared hero component - SASS
//============================================================================*/

.zen-hero {
  position: relative;
  margin: var(--rk-m-full-lg);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("title") {
    padding: 0;
  }
}
