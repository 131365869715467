/*==============================================================================
// File:        _people.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main people styles for the website - SASS
//============================================================================*/

.zen-people {
  .offcanvas-backdrop {
    margin-top: 0;
  }
}
