/*==============================================================================
// File:        _search.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main search styles for the website - SASS
//============================================================================*/

.zen-search {
  padding: 0 0 var(--rk-p-xxl);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("top-bar") {
    border-bottom: 1px solid var(--rk-border-colour);
    border-top: 3px solid var(--rk-primary);
    padding: var(--rk-p-df) var(--rk-p-lg);
    justify-content: space-between;
    margin: 0 -30px var(--rk-m-df);
    align-items: center;
    min-height: 75px;
    max-height: 90px;
    display: flex;
    position: sticky;
    top: 106px;
    z-index: 20;
    background: white;

    @include media-breakpoint-down(lg) {
      top: 72px !important;
    }
  }

  @include element("tags") {
    margin: 0 0 var(--rk-m-df);
    padding: var(--rk-p-df) 0;

    .rk-btn--clear .rk-icon {
      display: none;
    }
  }

  @include element("results") {
    padding: 0;
  }
}
