/*==============================================================================
// File:        _tab.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared tab component - SASS
//============================================================================*/

.zen-tab {
  background: var(--rk-base-white);
  padding: var(--rk-p-xl);
  border: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("heading-item") {
    display: inline-block;
  }

  @include element("content-pane") {
    padding: var(--rk-div-p-xl) 0;
    display: none;

    @include modifier("active") {
      display: block;
    }

    @include modifier("no-pad") {
      padding: 0;
    }

    @include adjoin("active") {
      @extend %zen-tab__content-pane--active;
    }
  }

  @include element("link") {
    border: 0;
    letter-spacing: 1px;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    font-size: var(--rk-font-size-xs);
    font-weight: var(--rk-font-semi-bolder);
    text-decoration: none;
    padding: var(--rk-p-sm) var(--rk-p-md);
    position: relative;
    margin: 0 2px 0 0;
    border-radius: 0;
    background: none;
    color: var(--rk-primary);
    display: block;

    &:after {
      @include pseudo;
    }

    &:hover,
    &:focus {
      background: var(--rk-grey-lightest);
      text-decoration: none;
      color: var(--rk-text);
      cursor: pointer;
    }

    @include modifier("active") {
      background: var(--rk-primary);
      color: var(--rk-base-white);
      position: relative;

      &:after {
        border-top: 12px solid var(--rk-primary);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        bottom: -10px;
        right: 50%;
        transform: translate(50%, 0);
        height: 0;
        width: 0;
      }
    }

    @include modifier("current") {
      @extend %zen-tab__link--active;
    }

    @include adjoin("active") {
      @extend %zen-tab__link--active;
    }
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("bordered") {
    .zen-tab__link {
      color: var(--rk-text);

      @include adjoin("active") {
        border-bottom: 2px solid var(--rk-primary);
        background: var(--rk-base-white);

        &:after {
          border-bottom: 6px solid var(--rk-primary);
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-top: none;
          bottom: -1px;
          right: 43%;
          height: 0;
          width: 0;
        }
      }
    }
  }
}

/*------------------------------------------------------------------------------
// Extend tab element & modifiers
//----------------------------------------------------------------------------*/

%zen-tab {
  @extend .zen-tab;
}

%zen-tab--bordered {
  @extend .zen-tab--bordered;
}

%zen-tab__heading-item {
  @extend .zen-tab__heading-item;
}

%zen-tab__content-pane {
  @extend .zen-tab__content-pane;
}

%zen-tab__content-pane--active {
  @extend .zen-tab__content-pane--active;
}

%zen-tab__link {
  @extend .zen-tab__link;
}

%zen-tab__link--active {
  @extend .zen-tab__link--active;
}
