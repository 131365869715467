/*==============================================================================
// File:        line-limit.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared line limit layouts - SASS
//============================================================================*/

.zen-line-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("two") {
    -webkit-line-clamp: 2;
  }

  @include modifier("three") {
    -webkit-line-clamp: 3;
  }

  @include modifier("four") {
    -webkit-line-clamp: 4;
  }
}
