/*==============================================================================
// File:        _footer.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main footer styles for the website - SASS
//============================================================================*/

.zen-footer {
  z-index: var(--rk-z-index-bottom);
  background: var(--rk-grey-lightest);
  color: var(--rk-secondary);
  position: relative;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    @include media-breakpoint-down(lg) {
      text-align: center;

      .sppb-text-left {
        text-align: center;
      }

      .zen-footer-menu .zen-list {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        column-gap: normal;
        column-rule: none;
        column-count: 1;
        max-width: 100%;
        margin: 0 auto;
        display: flex;

        li {
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  @include element("signup") {
    background: var(--rk-primary);
    color: var(--rk-base-white);

    .btn {
      @extend %zen-btn;

      width: auto;
    }
  }

  @include element("signup-container") {
    padding: var(--rk-p-df) var(--rk-p-md) var(--rk-p-xxl);
    max-width: 600px;
    margin: 0 auto;
  }

  @include element("copyright") {
    font-size: var(--rk-font-size-xs);
    background: var(--rk-grey-lightest);
    padding: var(--rk-p-lg) var(--rk-p-df);
    color: var(--rk-dark);
    margin: 0 0 var(--rk-m-xl);
    text-align: center;

    @include media-breakpoint-down(md) {
      margin: 0 0 var(--rk-m-df);
    }

    a {
      color: var(--rk-secondary);
    }
  }
}
