/*==============================================================================
// File:        _blog.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main blog styles for the website - SASS
//============================================================================*/

.zen-blog {
  overflow: hidden;
  padding: 0;

  // ------------------------------------------------//
  // Blog Index 'More' Card
  // ------------------------------------------------ //

  @include element("card-more") {
    border-top: 1px solid var(--rk-base-grey-light);
    padding: var(--rk-p-df) 0;

    .card-image-wrapper {
      background-color: (--rk-base-grey-light);
      padding: 0;
    }

    .card-image {
      background-size: cover;
      height: 137px;
      max-width: 158px;
      background-color: var(--rk-base-grey-lighter);
    }

    a {
      text-decoration: none;
    }

    .date {
      background: var(--rk-primary);
      color: var(--rk-base-white);
      font-size: var(--rk-font-size-xs);
      display: inline-block;
      margin-top: var(--rk-m-sm);
      padding: var(--rk-p-xs);
      font-style: italic;
      font-weight: var(--rk-font-bold);
    }
  }

  @include element("search") {
    .awesomplete {
      width: 100% !important;
    }
  }

  @include element("card-result") {
    .card-image {
      background-size: cover;
      height: 200px;
      max-width: 200px;
      background-color: var(--rk-base-grey-lighter);
    }
  }

  // ------------------------------------------------//
  // Blog Pagination
  // ------------------------------------------------ //

  @include element("article-pagination") {
    .pagenav {
      @extend .zen-list;
      @extend .zen-list--inline;

      justify-content: center;
      align-items: center;
      padding: var(--rk-p-lg) 0;
      display: flex;

      .next,
      .previous {
        a {
          @extend .zen-btn;
        }
      }

      li {
        a span {
          background-color: transparent;
          display: inline-block;
        }

        .icon-chevron-left,
        .icon-chevron-right {
          @extend %fontello;

          vertical-align: middle;
          padding: 0;
        }
      }
    }
  }
}

// ------------------------------------------------//
// Blog Index - Specific Styling
// ------------------------------------------------ //

.zen-blog-category {
  // Centering Pagination Items
  .pagination__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Grid Styling for featureblock
  .blog-featureblock {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
    min-height: 40vw;

    .div1 {
      grid-area: 1 / 1 / 3 / 3;
    }

    .div2 {
      grid-area: 1 / 3 / 2 / 5;
    }

    .div3 {
      grid-area: 2 / 3 / 3 / 4;
    }

    .div4 {
      grid-area: 2 / 4 / 3 / 5;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: auto;
      grid-template-rows: auto;

      /*Adjust grid areas for mobile layout*/
      .div1 {
        grid-area: auto;
      }

      .div2 {
        grid-area: auto;
      }

      .div3 {
        grid-area: auto;
      }

      .div4 {
        grid-area: auto;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .zen-cta-medium {
        container-type: unset;
        container-name: unset;
        max-width: unset;
      }

      .div1,
      .div2,
      .div3,
      .div4 {
        grid-area: unset;
        display: block;
      }

      .zen-cta--featurebox {
        aspect-ratio: unset;
        height: 400px;
        margin-bottom: 30px !important;

        .zen-cta__featurebox-content p {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }

      .zen-cta--rectangle .zen-cta__featurebox-image {
        height: 200%;
        width: 200%;
        max-height: 400px;
      }
    }
  }
}

// ------------------------------------------------//
// Stuff for Smart Search
// ------------------------------------------------//

.com-finder {
  min-height: 200px;
}

.mod-finder__search {
  display: flex;
  flex-grow: unset;
  align-items: center;
  flex-wrap: nowrap;

  input {
    height: 60px;
  }
}

.com-finder__search {
  .awesomplete,
  .js-finder-search-query {
    width: 100%;
    height: 60px;
  }
}

.zen-card--blog-card__image {
  position: relative;
}

.zen-card--blog-card__image .card-image {
  background-size: cover;
  height: 200px;
}

.zen-card--blog-card__date {
  position: absolute;
  left: 35px;
  bottom: 20px;
  background: var(--rk-primary);
  display: inline-block;
  margin-top: var(--rk-m-sm);
  padding: var(--rk-p-xs);

  .result__date {
    font-size: var(--rk-font-size-xs);
    font-weight: var(--rk-font-bold);
    font-style: italic;
    color: var(--rk-base-white);
  }
}
