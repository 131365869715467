/*==============================================================================
// File:        _holiday.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main holiday styles for the website - SASS
//============================================================================*/

.zen-holiday {
  overflow-x: hidden;

  // ----------------------------------------
  // Holiday Hero
  // ----------------------------------------

  @include element("hero") {
    .slick-list {
      margin: 0;
    }

    .zen-slider-nav {
      max-width: 1200px;
      max-height: 175px;
      overflow: hidden;
      margin: 0 auto;
      opacity: 0;

      @include adjoin("slick-initialized") {
        opacity: 1;
      }

      .slick-current {
        position: relative;

        @include adjoin("slick-active") {
          &:before {
            border-bottom: 8px solid var(--rk-primary);
            border-right: 6px solid transparent;
            border-left: 8px solid transparent;
            transform: translate(50%, 0);
            position: absolute;
            content: "";
            top: -8px;
            right: 50%;
            height: 0;
            width: 0;
          }

          &:after {
            border: 4px solid var(--rk-primary);
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
          }
        }
      }

      .slick-list {
        margin-right: -15px;
      }

      .slick-slide {
        margin-right: var(--rk-p-df);

        &:hover {
          cursor: pointer;

          &:before {
            border-bottom: 8px solid var(--rk-base-grey-light);
            border-right: 6px solid transparent;
            border-left: 8px solid transparent;
            transform: translate(50%, 0);
            position: absolute;
            content: "";
            top: -8px;
            right: 50%;
            height: 0;
            width: 0;
          }

          &:after {
            border: 4px solid var(--rk-base-grey-light);
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }
    }

    .slider-wrapper {
      position: relative;
      overflow: hidden;

      @include media-breakpoint-down(xl) {
        max-height: 800px;
      }

      .zen-slider {
        max-height: 800px;
      }

      .slick-slide {
        max-height: 800px;
        margin: 0;
      }

      .lightbox-trigger-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 10px 15px;
        background: white;
        color: black;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      &:after {
        border: 2px solid var(--rk-border-light);
        position: absolute;
        inset: 10px;
        content: "";
        pointer-events: none;
      }

      .zen-btn {
        position: absolute;
        bottom: 30px;
        right: 30px;
      }

      .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .slick-dots {
        display: flex;
        list-style: none;
        position: absolute;
        z-index: 20;
        bottom: 8%;
        padding: 0 40px;
        margin: auto;
        left: 0;
        right: 0;
        gap: 2px;

        li {
          margin: auto;
        }

        button {
          background: transparent;
          border: 1px solid white;
          font-size: 0;
          height: 10px;
          width: 10px;
          transition: all .3s;
          padding: 0;

          &:hover {
            background: white;
          }
        }

        .slick-active button {
          background: white;
        }
      }
    }

    .zen-slider {
      opacity: 0;

      & ~ .zen-btn {
        opacity: 0;
      }

      @include adjoin("slick-initialized") {
        opacity: 1;

        & ~ .zen-btn {
          opacity: 1;
        }

        & ~ .loading-spinner {
          display: none;
        }
      }
    }
  }

  @include element("social-share-buttons") {
    scroll-margin-top: 100px;
  }

  @include element("social-share") {
    a .a2a_svg {
      color: var(--rk-base-grey);
      transition: all .2s ease-in-out;
      margin: 0;
    }

    a:hover {
      .a2a_svg {
        color: var(--rk-primary);
      }
    }
  }

  @include element("favourite") {
    color: var(--rk-default-lighter);
    position: relative;

    &:hover,
    &:focus {
      color: var(--rk-default);
    }

    @include adjoin("fav-active") {
      .icon-heart,
      .zen-icon {
        color: var(--rk-primary);

        &:before {
          content: "\e85e" !important;
          font-size: 22px;
          position: relative;
        }

        &:hover,
        &:focus {
          color: var(--rk-default);
        }
      }
    }
  }

  @include element("hero-categories") {
    border: 1px solid var(--rk-base-grey-light);
    padding: var(--rk-p-sm);

    .category-item {
      padding: 0 var(--rk-p-df);
      width: 33%;
    }

    .holiday-type {
      border-right: 1px solid var(--rk-base-grey-light);
    }

    .holiday-grade {
      border-left: 1px solid var(--rk-base-grey-light);
      position: relative;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: var(--rk-base-grey-lighter);
      }
    }

    .holiday-grade-info {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  // ----------------------------------------
  // Holiday Quick Links
  // ----------------------------------------

  @include element("quick-links") {
    background-color: var(--rk-base-white);
    box-shadow: 0 20px 20px rgba($base-black, .02);
    border-top: 1px solid var(--rk-base-grey-light);
    z-index: $z-index-middle;
    padding: 0;

    &.sticky-links {
      position: fixed;
      top: 80px;
      left: 0;
      right: 0;

      @include media-breakpoint-down(lg) {
        top: 70px;
      }
    }
  }

  @include element("quick-links-mobile") {
    border-top: 1px solid var(--rk-base-grey-light);
    box-shadow: 0 20px 20px rgba($base-black, .02);
    padding: 15px;
    width: 100%;
  }

  // ----------------------------------------
  // Top Mobile
  // ----------------------------------------

  @include element("top-mobile") {
    border-bottom: 1px solid #F5F3F4;
  }

  // ----------------------------------------
  // Intro Section
  // ----------------------------------------

  @include element("intro") {
    color: var(--rk-base-black);
    max-width: 960px;
    margin: 0 auto;

    h6 {
      font-size: 16px;
      letter-spacing: 1.6px;
    }

    .min-age {
      font-size: 34px;
      margin-top: 17px;
    }

    .note-box.note-box__gray-lightest p {
      font-size: var(--rk-font-size-md) !important;
      line-height: 1.8;
    }
  }

  .content-truncated {
    max-height: 100px;
    overflow: hidden;
    transition: max-height .3s ease;
  }

  .content-truncated.expanded {
    max-height: 2000px;

    &:after {
      opacity: 0;
    }
  }

  @include element("intro-description") {
    p {
      font-size: var(--rk-font-size-md);
    }
  }

  .for-you-truncated {
    position: relative;
  }

  .for-you-truncated:after {
    content: "";
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(var(--bs-light-rgb), var(--bs-bg-opacity)), transparent);
  }

  // ----------------------------------------
  // Why KE & Tour Leader
  // ----------------------------------------

  @include element("usp") {
    padding: 50px;
    border: 3px solid var(--rk-primary);

    @include media-breakpoint-down(md) {
      text-align: center;
      padding: 40px 24px;
      border: 2px solid var(--rk-primary);
      margin-left: 0;
    }

    .leader-image {
      margin-left: var(--rk-m-df);
      min-height: 120px;
      max-width: 120px;
      aspect-ratio: 1/1;
      width: 100%;
    }
  }

  @include element("tour-leader") {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid var(--rk-base-grey-light);
    }

    @include media-breakpoint-down(lg) {
      .zen-btn {
        width: 100%;
      }
    }
  }

  // ----------------------------------------
  // Gallery Section Styling
  // ----------------------------------------

  @include element("gallery") {
    padding: var(--rk-p-xxl) 0;

    .tab-content {
      overflow: hidden;
      padding-bottom: var(--rk-p-lg);
    }

    .zen-slider {
      max-height: 85vh;

      @include media-breakpoint-up(md) {
        overflow: hidden;
      }

      .slick-next .icon-angle-right:before,
      .slick-prev .icon-angle-left:before {
        text-shadow: 0 0 5px rgba(0, 0, 0, 90%);
      }
    }

    .slick-list {
      margin: 0;
    }

    @include media-breakpoint-down(md) {
      padding: var(--rk-p-xxl) 0;

      .slick-arrow.slick-next,
      .slick-arrow.slick-prev {
        border: 3px solid var(--rk-primary);
        color: var(--rk-primary);
        margin-top: var(--rk-m-sm);
        height: 28px;
        width: 28px;
        top: 100%;
      }

      .slick-next .icon-angle-right:before,
      .slick-prev .icon-angle-left:before {
        color: var(--rk-primary);
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        font-size: 28px;
      }

      .slick-next .icon-angle-right:before {
        left: -2px;
      }

      .slick-prev .icon-angle-left:before {
        left: -4px;
      }

      .slick-arrow.slick-next {
        right: 0;
      }

      .slick-arrow.slick-prev {
        left: 0;
      }
    }
  }

  // ----------------------------------------
  // Itinerary Section Styling
  // ----------------------------------------

  @include element("itinerary") {
    .accordion-header {
      padding-right: 60px;
    }
  }

  // ----------------------------------------
  // Map Section Styling
  // ----------------------------------------

  @include element("map-key") {
    z-index: var(--rk-z-index-bottom);
    background: rgba(0, 0, 0, 80%);
    max-width: 960px;
    position: relative;
    margin: -170px auto 100px;
    padding: var(--rk-p-sm) var(--rk-p-lg);
    width: 70vw;

    @include media-breakpoint-down(lg) {
      margin: -145px auto 100px;
      max-width: 70vw;
    }

    .map-key-image {
      width: 32px;
    }
  }

  // ----------------------------------------
  // Essential and Review Styling
  // ----------------------------------------

  @include element("essentials") {
    max-width: 960px;
    margin: 0 auto;
  }

  @include element("reviews") {
    max-width: 960px;
    margin: 0 auto;
  }

  @include element("mobile-buttons") {
    padding: var(--rk-p-sm);
    background: var(--rk-base-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 30%);
    position: fixed;
    bottom: -100px;
    left: 0;
    right: 0;
    z-index: 100;
    transition: all .3s ease-in-out;

    &.sticky-buttons {
      bottom: 0;
    }
  }

  //----------------------------------------
  // Extension Related Styling
  //----------------------------------------

  @include element("extension-prices") {
    background: var(--rk-base-grey-lightest);
  }

  @include element("extension-price") {
    .col-inner {
      border-bottom: 1px solid var(--rk-grey);
      margin-bottom: var(--rk-m-sm);
      padding: var(--rk-p-sm);
    }
  }

  @include element("extension-list") {
    ul {
      padding-left: 0;
      line-height: 30px;
    }
  }

  @include element("extensions-included") {
    .col-inner {
      border-left: 1px solid var(--rk-base-grey-light);
      padding-left: var(--rk-p-lg);
    }
  }

  @include element("extension-gallery") {
    padding-bottom: var(--rk-p-xxl);
    overflow: hidden;

    .slick-dots {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      gap: 10px;
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;

      li {
        width: 15px;
        height: 15px;
        border: 1px solid var(--rk-base-grey);
        background: var(--rk-base-grey);
      }

      button {
        display: none;
      }

      .slick-active {
        background: var(--rk-base-black);
        border: 1px solid var(--rk-base-black);
      }
    }

    .slick-list {
      margin: 0;
    }

    .slider-wrapper {
      position: relative;

      &:after {
        border: 2px solid var(--rk-border-light);
        position: absolute;
        inset: 10px;
        content: "";
        top: 30px;
      }
    }
  }

  .grade-item__image {
    max-width: 110px;
    max-height: 45px;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: 72px;
    }
  }
}

// ----------------------------------------
// Holiday Price Panel Styling
// ----------------------------------------

.zen-holiday-prices {
  @include element("departure") {
    border: none;
    border-bottom: 1px solid var(--rk-base-grey-light);
    padding: var(--rk-p-xs) 0;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      border-bottom: none;
      border-top: 1px solid var(--rk-base-grey-light);
      padding: var(--rk-p-md) 0;
    }

    .accordion-header {
      .zen-holiday-prices--chevron:before {
        rotate: -180deg;
        transition: all .3s ease-in-out;
      }

      .collapsed .zen-holiday-prices--chevron:before {
        rotate: 0deg;
      }
    }
  }

  @include element("notes") {
    h4 {
      font-size: var(--rk-font-size-lg);
    }
  }

  @include element("date") {
    @include media-breakpoint-down(lg) {
      font-size: var(--rk-font-size-lead);
    }
  }

  @include element("price") {
    .js-price-col {
      @include media-breakpoint-down(lg) {
        background-color: var(--rk-base-grey-lightest);
        justify-content: space-between;
        padding: var(--rk-p-sm);
        margin: 3px 0;
        width: 100%;
      }
    }
  }

  @include element("flight-box") {
    background-color: var(--rk-grey-lightest);
  }

  @include element("header") {
    border-bottom: 1px solid var(--rk-base-grey-light);

    @include media-breakpoint-down(lg) {
      border-bottom: none;
      display: none;
    }
  }

  @include element("expanded") {
    background-color: var(--rk-base-grey-lightest);
    margin: var(--rk-m-sm) 0;
  }
}
