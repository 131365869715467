/*==============================================================================
// File:        _article.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared article component - SASS
//============================================================================*/

.zen-article {
  margin: 0 var(--rk-m-df) var(--rk-m-df);

  /*==============================================================================
  // Elements
  //============================================================================*/

  // ------------------------------------------------//
  // BLOG SINGLE ARTICLE STYLES
  // ------------------------------------------------ //

  @include element("body") {
    margin: var(--rk-m-xxl) auto;
    padding: var(--rk-p-md) 0;
    max-width: 1100px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    ul,
    li {
      padding: 0 80px;

      @include media-breakpoint-down(lg) {
        padding: 0 var(--rk-p-xl);
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  @include element("info-bar") {
    border-top: 1px solid var(--rk-base-grey-lighter);
  }

  @include element("image") {
    position: relative;
    max-height: 600px;
    overflow: hidden;

    &:after {
      border: 2px solid var(--rk-border-light);
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      bottom: 20px;
    }
  }

  @include element("title") {
    margin: 0 0 var(--rk-m-df);
  }

  @include element("content") {
    flex-direction: column;
    position: relative;
    padding: var(--rk-p-xs);
    display: flex;
  }
}

/*------------------------------------------------------------------------------
// Extend article element & modifiers
//----------------------------------------------------------------------------*/

%zen-article {
  @extend .zen-article;
}

%zen-article__image {
  @extend .zen-article__image;
}

%zen-article__title {
  @extend .zen-article__title;
}

%zen-article__content {
  @extend .zen-article__content;
}
