/*==============================================================================
// File:        divider.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared divier layouts - SASS
//============================================================================*/

.zen-divider {
  border-color: var(--rk-border-colour);
  opacity: 1;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("primary") {
    border-color: var(--rk-primary);
  }

  @include modifier("secondary") {
    border-color: var(--rk-secondary);
  }

  @include modifier("tertiary") {
    border-color: var(--rk-tertiary);
  }

  @include modifier("dark") {
    border-color: var(--rk-dark);
  }
}
