/*==============================================================================
// File:        _fonts.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main web font references - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// ZEN Icons - Source: "http://fontello.com/"
//----------------------------------------------------------------------------*/

@font-face {
  font-family: fontello;
  src: url("../fonts/fontello.eot");
  src:
    url("../fonts/fontello.eot#iefix") format("embedded-opentype"),
    url("../fonts/fontello.woff") format("woff"),
    url("../fonts/fontello.ttf") format("truetype"),
    url("../fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

.fontello {
  /*Use important to prevent issues*/
  font-family: fontello, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  speak: none;

  &:before {
    margin: 0 !important;
  }
}

.icon-back:before { content: "\e800"; }
.icon-backpack:before { content: "\e801"; }
.icon-calendar:before { content: "\e802"; }
.icon-book-open:before { content: "\e803"; }
.icon-camera:before { content: "\e804"; }
.icon-chevron-down:before { content: "\e805"; }
.icon-chevron-left:before { content: "\e806"; }
.icon-chevron-right:before { content: "\e807"; }
.icon-chevron-up:before { content: "\e808"; }
.icon-arrow-up-right:before { content: "\e809"; }
.icon-email:before { content: "\e80a"; }
.icon-harness:before { content: "\e80b"; }
.icon-heart:before { content: "\e80c"; }
.icon-climbing:before { content: "\e80d"; }
.icon-twitter:before { content: "\e824"; }
.icon-culture:before { content: "\e80f"; }
.icon-family:before { content: "\e810"; }
.icon-multi-activity:before { content: "\e811"; }
.icon-photography:before { content: "\e812"; }
.icon-polar:before { content: "\e813"; }
.icon-schools:before { content: "\e814"; }
.icon-walk-and-trek:before { content: "\e815"; }
.icon-wildlife:before { content: "\e816"; }
.icon-winter:before { content: "\e817"; }
.icon-pin:before { content: "\e818"; }
.icon-phone:before { content: "\e819"; }
.icon-planet:before { content: "\e81a"; }
.icon-play:before { content: "\e81b"; }
.icon-save-the-planet:before { content: "\e81c"; }
.icon-search:before { content: "\e81d"; }
.icon-sliders:before { content: "\e81e"; }
.icon-star:before { content: "\e81f"; }
.icon-tag:before { content: "\e820"; }
.icon-user:before { content: "\e821"; }
.icon-close:before { content: "\e822"; }
.icon-signpost:before { content: "\e823"; }
.icon-facebook:before { content: "\e825"; }
.icon-instagram:before { content: "\e826"; }
.icon-youtube:before { content: "\e827"; }
.icon-open:before { content: "\e828"; }
.icon-sys-alert:before { content: "\e829"; }
.icon-sys-hamburger:before { content: "\e82a"; }
.icon-sys-info:before { content: "\e82b"; }
.icon-sys-login:before { content: "\e82c"; }
.icon-sys-tick:before { content: "\e82d"; }
.icon-pinterest:before { content: "\e82e"; }
.icon-bed:before { content: "\e82f"; }
.icon-check-circle:before { content: "\e830"; }
.icon-compass:before { content: "\e831"; }
.icon-download:before { content: "\e832"; }
.icon-external-link:before { content: "\e833"; }
.icon-food:before { content: "\e834"; }
.icon-image:before { content: "\e835"; }
.icon-info:before { content: "\e836"; }
.icon-map:before { content: "\e837"; }
.icon-plane:before { content: "\e838"; }
.icon-unfold-more:before { content: "\e839"; }
.icon-users:before { content: "\e83a"; }
.icon-alert-circle:before { content: "\e83b"; }
.icon-alert-triangle:before { content: "\e83c"; }
.icon-check:before { content: "\e83d"; }
.icon-clock:before { content: "\e83e"; }
.icon-arrow-down-right:before { content: "\e83f"; }
.icon-measure:before { content: "\e840"; }
.icon-arrow-right:before { content: "\e841"; }
.icon-self-guided:before { content: "\e842"; }
.icon-guided-group:before { content: "\e843"; }
.icon-sea-kayak:before { content: "\e844"; }
.icon-unesco:before { content: "\e845"; }
.icon-volcanoes:before { content: "\e846"; }
.icon-camel-trek:before { content: "\e847"; }
.icon-cross-country-skiing:before { content: "\e848"; }
.icon-cooking:before { content: "\e849"; }
.icon-rock-climbing:before { content: "\e84a"; }
.icon-kayaking:before { content: "\e84b"; }
.icon-boat-ride:before { content: "\e84c"; }
.icon-canoeing:before { content: "\e84d"; }
.icon-white-water-rafting:before { content: "\e84e"; }
.icon-horse-riding:before { content: "\e84f"; }
.icon-husky-sled-rides:before { content: "\e850"; }
.icon-mountaineer:before { content: "\e80D"; }
.icon-walking:before { content: "\e852"; }
.icon-snowshoeing:before { content: "\e853"; }
.icon-animals-and-wildlife:before { content: "\e854"; }
.icon-sand-dunes:before { content: "\e855"; }
.icon-cave-visit:before { content: "\e856"; }
.icon-school-visit:before { content: "\e857"; }
.icon-cycle:before { content: "\e858"; }
.icon-canyoning:before { content: "\e859"; }
.icon-geocaching:before { content: "\e85a"; }
.icon-adventure-park:before { content: "\e85b"; }
.icon-surfing:before { content: "\e85c"; }
.icon-swimming:before { content: "\e85d"; }
.icon-heart-solid:before { content: "\e85e"; }
.icon-angle-left:before { content: "\f104"; }
.icon-angle-right:before { content: "\f105"; }
.icon-angle-up:before { content: "\f106"; }
.icon-angle-down:before { content: "\f107"; }
.icon-pioneer:before { content: "\e80e"; }
.icon-trail-running:before { content: "\e85f"; }
.icon-whatsapp:before { content: "\f232"; }
.icon-mobile:before { content: "\e860"; }
.icon-plus-circled:before { content: "\e861"; }
.icon-mail-alt:before { content: "\f0e0"; }
.icon-copy:before { content: "\f0c5"; }
.icon-share:before { content: "\e862"; }

//Activity mapping for iconsfh
//do not replace when updating fontello set
.icon-family-bike-rides:before { content: "\e858"; }
.icon-leisure-cycling:before { content: "\e858"; }
.icon-mountain-biking:before { content: "\e858"; }
.icon-e-biking:before { content: "\e858"; }
.icon-road-cycling:before { content: "\e858"; }
.icon-adventure-cycling:before { content: "\e858"; }
.icon-cycle-rides:before { content: "\e858"; }
.icon-mountaineering:before { content: "\e80D"; }
.icon-nordic-skiing:before { content: "\e848"; }
.icon-safari:before { content: "\e854"; }
.icon-overland-tour:before { content: "\e815"; }
.icon-trekking-peak:before { content: "\e815"; }
.icon-running:before { content: "\e815"; }
.icon-cruise:before { content: "\e84c"; }
.icon-walk-and-drive:before { content: "\e852"; }
.icon-coastal-walking:before { content: "\e852"; }
.icon-trekking:before { content: "\e852"; }

/*------------------------------------------------------------------------------
// Font Awesome Overrides
//----------------------------------------------------------------------------*/
.fa {
  &-long-arrow-left {
    &:before {
      content: "\e801";
    }
  }

  &-long-arrow-right {
    &:before {
      content: "\e802";
    }
  }
}

/*------------------------------------------------------------------------------
// Extend Icon Class
//----------------------------------------------------------------------------*/

%fontello {
  @extend .fontello;
}
