/*==============================================================================
// File:        _tag.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared tag component - SASS
//============================================================================*/

.zen-tag {
  font-weight: var(--rk-font-semi-bolder);
  font-size: var(--rk-font-size-sm);
  padding: 10px 12px 8px;
  color: var(--rk-base-white);
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  line-height: 1em;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("green") {
    background: $green-tag;
    color: var(--rk-base-black);
    font-size: var(--rk-font-size-xs);
    letter-spacing: 1px;
  }

  @include modifier("default") {
    background: $yellow-tag;
  }

  @include modifier("primary") {
    background: $primary-tag;
  }

  @include modifier("secondary") {
    background: $secondary-tag;
  }
}
